.team-grid{
	padding: 40px 0 0;
	.item{
		padding-bottom: 20px;
		.member_name{
			font-family: 'Averta-Regular';
			font-size: 30px;
			color: $moderna;
			@media only screen and (max-width: 767px){
				font-size: 24px;
				text-align: center;
			}
		}	
		.member_position{
			font-family: 'Averta-Regular';
			font-size: 22px;
			color: $grey;
			@media only screen and (max-width: 767px){
				font-size: 18px;
				text-align: center;
			}
		}	
		.member_content{
			padding: 10px 0;
		
			img{
				margin: 1em  auto;
				display: block;
				@media only screen and (max-width: 767px){
					max-height:200px;
				}
				
			}
		}	
		.team-contact{
			ul{
				@media only screen and (max-width: 767px){
					text-align: center;
				}
				li{
					.over {
					    z-index: 10;
					     position: relative;
					     display: inline-block;
					}
					a{
						display: inline-block;
						padding: 10px;
					    line-height: 1;
					    position: relative;
					    margin: 5px 0;
				        border: 1px solid $moderna;
					    &:after {
					    		background-color: $moderna; 
							    content: '';
							    z-index: 1;
							    width: 100%;
							    height: 100%;
							    display: block;
						        left: 0;
	   							top: 0;
							    position:absolute;
							    -moz-transform-origin: right 50%;
							    -ms-transform-origin: right 50%;
							    -webkit-transform-origin: right 50%;
							    transform-origin: right 50%;
							    -moz-transform: scaleX(0);
							    -ms-transform: scaleX(0);
							    -webkit-transform: scaleX(0);
							    transform: scaleX(0);
							    -moz-transition-property: -moz-transform;
							    -o-transition-property: -o-transform;
							    -webkit-transition-property: -webkit-transform;
							    transition-property: transform;
							    -moz-transition-duration: 380ms;
							    -o-transition-duration: 380ms;
							    -webkit-transition-duration: 380ms;
							    transition-duration: 380ms;
							}
							svg{
								width: 30px;
								height: 30px;
								display: inline-block;
								fill: $moderna;
								-moz-transition: all 277ms cubic-bezier(.16,.01,.77,1);
							    -o-transition: all 277ms cubic-bezier(.16,.01,.77,1);
							    -webkit-transition: all 277ms cubic-bezier(.16,.01,.77,1);
							    transition: all 277ms cubic-bezier(.16,.01,.77,1);
							}
							&:hover {
								svg{
									fill: #fff;
								}

							}

							&:hover:after  {
							    -moz-transform-origin: left 50%;
							    -ms-transform-origin: left 50%;
							    -webkit-transform-origin: left 50%;
							    transform-origin: left 50%;
							    -moz-transform: scaleX(1);
							    -ms-transform: scaleX(1);
							    -webkit-transform: scaleX(1);
							    transform: scaleX(1);
							}
						
					}
				}
			}
		}
	}
}