.history-dates{
	padding: 50px 0;
	overflow: hidden;
	@media only screen and (max-width: 991px){
		padding: 40px 0;
	}
	@media only screen and (max-width: 767px){
		padding: 30px 0;
	}
	.carousel-content{
		left: -50px;
		.slick-list {
			overflow: visible;			
		}
		.item.active-date{
			pointer-events: none;
			.date{
				color: $moderna;
				//transform: scale(2.8);
				transform: scale(1.7);
				transform-origin: center center;
				margin-bottom: 30px;
				@media only screen and (max-width: 991px){
					transform: scale(1.6);
					margin-bottom: 26px;
				}
				@media only screen and (max-width: 767px){
					transform: scale(1.4);
					margin-bottom: 22px;
				}
			}
			.dot{
				span{
					transform: scale(1.6);
					&:before{	
						border: 2px solid #595959;
					}	
				}
			}
		}
		.slick-slide{
			cursor: pointer;
		}
		.item{

			&.first-child{
				.dot{
					&:before{
						display: none;
					}	
					
				}
			}
			&.last-child{
				.dot{
					&:after{
						display: none;
					}	
					
				}
			}
			.date{
				font-size: 30px;
				color: #595959;
				text-align: center;
				transition: all 0.3s;
				line-height: 1;
				@media only screen and (max-width: 1200px){
					font-size: 28px;
				}
				@media only screen and (max-width: 991px){
					font-size: 26px;		
				}
				@media only screen and (max-width: 767px){
					font-size: 24px;	
				}
			}
			.dot{
				position: relative;
				padding: 5px;
				display: block;
				transition: all 0.3s;
				margin-top: 35px;
				@media only screen and (max-width: 991px){
					margin-top: 30px;
				}
				@media only screen and (max-width: 767px){
					margin-top: 25px;
				}
				&:before{
					position: absolute;
					content: "";
					width: 50%;
					height: 1px;
					left: 0;
					right: 50%;
					top: 50%;
					background: #595959;
					transform: translateY(-50%);
				}	
				&:after{
					position: absolute;
					content: "";
					width: 50%;
					height: 1px;
					right: 0;
					top:50%;
					background: #595959;
					transform: translateY(-50%);
				}
				span{
					position: relative;
					width: 19px;
					height: 19px;
					margin: 0 auto;
					display: table;
					padding: 5px;
					z-index: 1;
					transition: all 0.3s;
					&:before{	
						position: absolute;
						content: "";
						width: 14px;
						height: 14px;
						border: 2px solid #595959;
						border-radius: 50%;
						top: 50%;
						left: 50%;
						background: #fff;
						transform: translate(-50% , -50%);
						transition: all 0.3s;
					}	
				}

			}
		}
		
	}
	.slider-details{
		padding: 50px 0 50px 0px;
		@media only screen and (max-width: 767px){
			padding: 30px 0 0px 0px;
		}
		.slick-current{
			.pic{			
				.main-img{
					top: 20px;
					left: 0px;
				}
			}
		}
		.pic{
			width: 200px;
			float: left;
			padding-left: 0px;
			@media only screen and (max-width: 991px){
				padding-left: 66px;
			}
			@media only screen and (max-width: 767px){
				padding-left: 0px;
				float: right;
    			width: 100%;
			}
			.bg-img{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				opacity: 0.25;
			}
			.main-img{
				svg{
					fill: $moderna;
				    height: 100px;
				    display: block;
    				margin: 0 auto;
				}
				position: relative;
				top: 0;
				left: 0;
				transition: all 0.3s;
			}
		}
		.content{
			float: left;
			width: calc(100% - 200px);
			padding: 38px 30px 40px 38px;
			@media only screen and (max-width: 767px){
				padding: 38px 0px 30px 0px;
				width: 100%;
				display: block;
				width: 100%;
				float: right;
				text-align: center;
			}
			@media only screen and (max-width: 480px){
				padding: 38px 15px 20px 0px;
			}
			
			h4{
				font-family: Averta-Black,sans-serif;
				letter-spacing: 1.1px;
    			margin-bottom: 25px;
    			padding-top: 15px;
    			font-size: 1.5em;
			}
		}
	}
}



			