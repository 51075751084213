@font-face {
  font-family: 'Averta-Regular';
  src:  url('../fonts/Averta-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-Regular.otf')  format('opentype'),
        url('../fonts/Averta-Regular.woff') format('woff'), 
        url('../fonts/Averta-Regular.ttf')  format('truetype'), 
        url('../fonts/Averta-Regular.svg#Averta-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Thin';
  src:  url('../fonts/Averta-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-Thin.otf')  format('opentype'),
        url('../fonts/Averta-Thin.woff') format('woff'),
        url('../fonts/Averta-Thin.ttf')  format('truetype'),
        url('../fonts/Averta-Thin.svg#Averta-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-ThinItalic';
  src:  url('../fonts/Averta-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-ThinItalic.otf')  format('opentype'),
        url('../fonts/Averta-ThinItalic.woff') format('woff'),
        url('../fonts/Averta-ThinItalic.ttf')  format('truetype'),
        url('../fonts/Averta-ThinItalic.svg#Averta-ThinItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-SemiboldItalic';
  src:  url('../fonts/Averta-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-SemiboldItalic.otf')  format('opentype'),
        url('../fonts/Averta-SemiboldItalic.woff') format('woff'),
        url('../fonts/Averta-SemiboldItalic.ttf')  format('truetype'),
        url('../fonts/Averta-SemiboldItalic.svg#Averta-SemiboldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-RegularItalic';
  src:  url('../fonts/Averta-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-RegularItalic.otf')  format('opentype'),
        url('../fonts/Averta-RegularItalic.woff') format('woff'),
        url('../fonts/Averta-RegularItalic.ttf')  format('truetype'),
        url('../fonts/Averta-RegularItalic.svg#Averta-RegularItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-LightItalic';
  src:  url('../fonts/Averta-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-LightItalic.otf')  format('opentype'),
        url('../fonts/Averta-LightItalic.woff') format('woff'),
        url('../fonts/Averta-LightItalic.ttf')  format('truetype'),
        url('../fonts/Averta-LightItalic.svg#Averta-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Light';
  src:  url('../fonts/Averta-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-Light.otf')  format('opentype'),
        url('../fonts/Averta-Light.woff') format('woff'),
        url('../fonts/Averta-Light.ttf')  format('truetype'),
        url('../fonts/Averta-Light.svg#Averta-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-ExtrathinItalic';
  src:  url('../fonts/Averta-ExtrathinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-ExtrathinItalic.otf')  format('opentype'),
        url('../fonts/Averta-ExtrathinItalic.woff') format('woff'),
        url('../fonts/Averta-ExtrathinItalic.ttf')  format('truetype'),
        url('../fonts/Averta-ExtrathinItalic.svg#Averta-ExtrathinItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Extrathin';
  src:  url('../fonts/Averta-Extrathin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-Extrathin.otf')  format('opentype'),
        url('../fonts/Averta-Extrathin.woff') format('woff'),
        url('../fonts/Averta-Extrathin.ttf')  format('truetype'),
        url('../fonts/Averta-Extrathin.svg#Averta-Extrathin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-ExtraboldItalic';
  src:  url('../fonts/Averta-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-ExtraboldItalic.otf')  format('opentype'),
        url('../fonts/Averta-ExtraboldItalic.woff') format('woff'),
        url('../fonts/Averta-ExtraboldItalic.ttf')  format('truetype'),
        url('../fonts/Averta-ExtraboldItalic.svg#Averta-ExtraboldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Extrabold';
  src:  url('../fonts/Averta-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-Extrabold.otf')  format('opentype'),
        url('../fonts/Averta-Extrabold.woff') format('woff'),
        url('../fonts/Averta-Extrabold.ttf')  format('truetype'),
        url('../fonts/Averta-Extrabold.svg#Averta-Extrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-BoldItalic';
  src:  url('../fonts/Averta-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-BoldItalic.otf')  format('opentype'),
        url('../fonts/Averta-BoldItalic.woff') format('woff'),
        url('../fonts/Averta-BoldItalic.ttf')  format('truetype'),
        url('../fonts/Averta-BoldItalic.svg#Averta-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Bold';
  src:  url('../fonts/Averta-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-Bold.otf')  format('opentype'),
        url('../fonts/Averta-Bold.woff') format('woff'),
        url('../fonts/Averta-Bold.ttf')  format('truetype'),
        url('../fonts/Averta-Bold.svg#Averta-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-BlackItalic';
  src:  url('../fonts/Averta-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-BlackItalic.otf')  format('opentype'),
        url('../fonts/Averta-BlackItalic.woff') format('woff'),
        url('../fonts/Averta-BlackItalic.ttf')  format('truetype'),
        url('../fonts/Averta-BlackItalic.svg#Averta-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Black';
  src:  url('../fonts/Averta-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Averta-Black.otf')  format('opentype'),
        url('../fonts/Averta-Black.woff') format('woff'),
        url('../fonts/Averta-Black.ttf')  format('truetype'),
        url('../fonts/Averta-Black.svg#Averta-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

$moderna_logo:#002a43;
$moderna:#002a43;
$grey:#76777a;
$light_grey:#9c9c9c;



.moderna-logo{
    .cls-1{fill:$moderna;}
    .cls-2{fill:$grey;}
}

.moderna-logo.white{
    .cls-1{fill:#fff;}
    .cls-2{fill:#fff;}
}

[class*=" icon-"],
[class^=icon-] {
    font-family: Material Icons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
    display: block
}

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden] {
    display: none
}

* {
    outline: 0!important;
}

html {
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

button,
html,
input,
select,
textarea {
    font-family: sans-serif
}

body {
    margin: 0;
    font-family: 'Averta-Thin';
}

a:focus {
    outline: none
}

a:active,
a:hover {
    outline: 0
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

h2 {
    font-size: 1.5em;
    margin: .83em 0
}

h3 {
    font-size: 1.17em;
    margin: 1em 0
}

h4 {
    font-size: 1em;
    margin: 1.33em 0
}

h5 {
    font-size: .83em;
    margin: 1.67em 0
}

h6 {
    font-size: .67em;
    margin: 2.33em 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

blockquote {
    margin: 1em 40px
}

dfn {
    font-style: italic
}

hr {
    box-sizing: border-box;
    height: 0
}

mark {
    background: #ff0;
    color: #000
}

p,
pre {
    margin: 1em 0
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    _font-family: courier new, monospace;
    font-size: 1em
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
}

q {
    quotes: none
}

q:after,
q:before {
    content: "";
    content: none
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

dl,
menu,
ol,
ul {
    margin: 1em 0
}

dd {
    margin: 0 0 0 40px
}

menu,
ol,
ul {
    padding: 0 0 0 40px
}

nav ol,
nav ul {
    list-style: none;
    list-style-image: none
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic
}

svg:not(:root) {
    overflow: hidden
}

figure,
form {
    margin: 0
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
    *margin-left: -7px
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle
}

button,
input {
    line-height: normal
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
    *overflow: visible
}

button[disabled],
html input[disabled] {
    cursor: default
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px
}

input[type=search] {
    -webkit-appearance: textfield;
    box-sizing: border-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto;
    vertical-align: top
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

x-object-fit {
    position: relative !important;
    display: inline-block !important
}

x-object-fit>.x-object-fit-taller,
x-object-fit>.x-object-fit-wider {
    position: absolute !important;
    left: -100% !important;
    right: -100% !important;
    top: -100% !important;
    bottom: -100% !important;
    margin: auto !important
}

.x-object-fit-none>.x-object-fit-taller,
.x-object-fit-none>.x-object-fit-wider {
    width: auto !important;
    height: auto !important
}

.x-object-fit-fill>.x-object-fit-taller,
.x-object-fit-fill>.x-object-fit-wider {
    width: 100% !important;
    height: 100% !important
}

.x-object-fit-contain>.x-object-fit-taller {
    width: auto !important;
    height: 100% !important
}

.x-object-fit-contain>.x-object-fit-wider {
    width: 100% !important;
    height: auto !important
}

.x-object-fit-cover>.x-object-fit-taller,
.x-object-fit-cover>.x-object-fit-wider {
    max-width: none !important;
    max-height: none !important
}

.x-object-fit-cover>.x-object-fit-taller {
    width: 100% !important;
    height: auto !important;
    max-width: none !important
}

.x-object-fit-cover>.x-object-fit-wider {
    width: auto !important;
    height: 100% !important;
    max-width: none !important
}

.x-object-position-top>.x-object-fit-taller,
.x-object-position-top>.x-object-fit-wider {
    top: 0 !important;
    bottom: auto !important
}

.x-object-position-right>.x-object-fit-taller,
.x-object-position-right>.x-object-fit-wider {
    left: auto !important;
    right: 0 !important
}

.x-object-position-bottom>.x-object-fit-taller,
.x-object-position-bottom>.x-object-fit-wider {
    top: auto !important;
    bottom: 0 !important
}

.x-object-position-left>.x-object-fit-taller,
.x-object-position-left>.x-object-fit-wider {
    left: 0 !important;
    right: auto !important
}

.moz-background-picture {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat
}

.clearfix {
    *zoom: 1
}

.clearfix:after,
.clearfix:before {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.nocollapse:after,
.nocollapse:before {
    content: "\00a0";
    display: block;
    overflow: hidden;
    height: 0
}

.visuallyhidden,
.visuallyhidden--no-js {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.no-js .visuallyhidden--no-js {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.visuallyhidden--focusable {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.visuallyhidden--focusable:active,
.visuallyhidden--focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.grid {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -20px;
    letter-spacing: -.31em
}

.grid,
.opera:-o-prefocus {
    word-spacing: -.43em
}

.grid__item {
    display: inline-block;
    padding-left: 20px;
    vertical-align: top;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: normal;
    word-spacing: normal
}

.grid--rev {
    direction: rtl;
    text-align: left
}

.grid--rev>.grid__item {
    direction: ltr;
    text-align: left
}

.grid--full {
    margin-left: 0
}

.grid--full>.grid__item {
    padding-left: 0
}

.grid--right {
    text-align: right
}

.grid--right>.grid__item {
    text-align: left
}

.grid--center {
    text-align: center
}

.grid--center>.grid__item {
    text-align: left
}

.grid--middle>.grid__item {
    vertical-align: middle
}

.grid--bottom>.grid__item {
    vertical-align: bottom
}

.grid--narrow {
    margin-left: -10px
}

.grid--narrow>.grid__item {
    padding-left: 10px
}

.grid--wide {
    margin-left: -40px
}

.grid--wide>.grid__item {
    padding-left: 40px
}

.one-whole {
    width: 100%
}

.tab-col-item{
     float: none;
     display: block;
      padding-left:15px;
     padding-right: 15px;
    @media only screen and (min-width:768px) {
        float: left;
    }
}
.col-item{
     padding-left:15px;
     padding-right: 15px;
     float: none;
     @media only screen and (min-width:768px) {
        float: left;
    }
}

.five-tenths,
.four-eighths,
.one-half,
.six-twelfths,
.three-sixths,
.two-quarters {
    width: 50%
}

.four-twelfths,
.one-third,
.two-sixths {
    width: 33.333%
}

.eight-twelfths,
.four-sixths,
.two-thirds {
    width: 66.666%
}

.one-quarter,
.three-twelfths,
.two-eighths {
    width: 25%
}

.nine-twelfths,
.six-eighths,
.three-quarters {
    width: 75%
}

.one-fifth,
.two-tenths {
    width: 20%
}

.four-tenths,
.two-fifths {
    width: 40%
}

.six-tenths,
.three-fifths {
    width: 60%
}

.eight-tenths,
.four-fifths {
    width: 80%
}

.one-sixth,
.two-twelfths {
    width: 16.666%
}

.five-sixths,
.ten-twelfths {
    width: 83.333%
}

.one-eighth {
    width: 12.5%
}

.three-eighths {
    width: 37.5%
}

.five-eighths {
    width: 62.5%
}

.seven-eighths {
    width: 87.5%
}

.one-tenth {
    width: 10%
}

.three-tenths {
    width: 30%
}

.seven-tenths {
    width: 70%
}

.nine-tenths {
    width: 90%
}

.one-twelfth {
    width: 8.333%
}

.five-twelfths {
    width: 41.666%
}

.seven-twelfths {
    width: 58.333%
}

.eleven-twelfths {
    width: 91.666%
}

@media only screen and (min-width:520px) {
    .mob--one-whole {
        width: 100%
    }

    .mob--five-tenths,
    .mob--four-eighths,
    .mob--one-half,
    .mob--six-twelfths,
    .mob--three-sixths,
    .mob--two-quarters {
        width: 50%
    }

    .mob--four-twelfths,
    .mob--one-third,
    .mob--two-sixths {
        width: 33.333%
    }

    .mob--eight-twelfths,
    .mob--four-sixths,
    .mob--two-thirds {
        width: 66.666%
    }

    .mob--one-quarter,
    .mob--three-twelfths,
    .mob--two-eighths {
        width: 25%
    }

    .mob--nine-twelfths,
    .mob--six-eighths,
    .mob--three-quarters {
        width: 75%
    }

    .mob--one-fifth,
    .mob--two-tenths {
        width: 20%
    }

    .mob--four-tenths,
    .mob--two-fifths {
        width: 40%
    }

    .mob--six-tenths,
    .mob--three-fifths {
        width: 60%
    }

    .mob--eight-tenths,
    .mob--four-fifths {
        width: 80%
    }

    .mob--one-sixth,
    .mob--two-twelfths {
        width: 16.666%
    }

    .mob--five-sixths,
    .mob--ten-twelfths {
        width: 83.333%
    }

    .mob--one-eighth {
        width: 12.5%
    }

    .mob--three-eighths {
        width: 37.5%
    }

    .mob--five-eighths {
        width: 62.5%
    }

    .mob--seven-eighths {
        width: 87.5%
    }

    .mob--one-tenth {
        width: 10%
    }

    .mob--three-tenths {
        width: 30%
    }

    .mob--seven-tenths {
        width: 70%
    }

    .mob--nine-tenths {
        width: 90%
    }

    .mob--one-twelfth {
        width: 8.333%
    }

    .mob--five-twelfths {
        width: 41.666%
    }

    .mob--seven-twelfths {
        width: 58.333%
    }

    .mob--eleven-twelfths {
        width: 91.666%
    }
}

@media only screen and (min-width:768px) {
    .tab-port--one-whole {
        width: 100%
    }

    .tab-port--five-tenths,
    .tab-port--four-eighths,
    .tab-port--one-half,
    .tab-port--six-twelfths,
    .tab-port--three-sixths,
    .tab-port--two-quarters {
        width: 50%
    }

    .tab-port--four-twelfths,
    .tab-port--one-third,
    .tab-port--two-sixths {
        width: 33.333%
    }

    .tab-port--eight-twelfths,
    .tab-port--four-sixths,
    .tab-port--two-thirds {
        width: 66.666%
    }

    .tab-port--one-quarter,
    .tab-port--three-twelfths,
    .tab-port--two-eighths {
        width: 25%
    }

    .tab-port--nine-twelfths,
    .tab-port--six-eighths,
    .tab-port--three-quarters {
        width: 75%
    }

    .tab-port--one-fifth,
    .tab-port--two-tenths {
        width: 20%
    }

    .tab-port--four-tenths,
    .tab-port--two-fifths {
        width: 40%
    }

    .tab-port--six-tenths,
    .tab-port--three-fifths {
        width: 60%
    }

    .tab-port--eight-tenths,
    .tab-port--four-fifths {
        width: 80%
    }

    .tab-port--one-sixth,
    .tab-port--two-twelfths {
        width: 16.666%
    }

    .tab-port--five-sixths,
    .tab-port--ten-twelfths {
        width: 83.333%
    }

    .tab-port--one-eighth {
        width: 12.5%
    }

    .tab-port--three-eighths {
        width: 37.5%
    }

    .tab-port--five-eighths {
        width: 62.5%
    }

    .tab-port--seven-eighths {
        width: 87.5%
    }

    .tab-port--one-tenth {
        width: 10%
    }

    .tab-port--three-tenths {
        width: 30%
    }

    .tab-port--seven-tenths {
        width: 70%
    }

    .tab-port--nine-tenths {
        width: 90%
    }

    .tab-port--one-twelfth {
        width: 8.333%
    }

    .tab-port--five-twelfths {
        width: 41.666%
    }

    .tab-port--seven-twelfths {
        width: 58.333%
    }

    .tab-port--eleven-twelfths {
        width: 91.666%
    }
}

@media only screen and (min-width:769px) {
    .tab--one-whole {
        width: 100%
    }

    .tab--five-tenths,
    .tab--four-eighths,
    .tab--one-half,
    .tab--six-twelfths,
    .tab--three-sixths,
    .tab--two-quarters {
        width: 50%
    }

    .tab--four-twelfths,
    .tab--one-third,
    .tab--two-sixths {
        width: 33.333%
    }

    .tab--eight-twelfths,
    .tab--four-sixths,
    .tab--two-thirds {
        width: 66.666%
    }

    .tab--one-quarter,
    .tab--three-twelfths,
    .tab--two-eighths {
        width: 25%
    }

    .tab--nine-twelfths,
    .tab--six-eighths,
    .tab--three-quarters {
        width: 75%
    }

    .tab--one-fifth,
    .tab--two-tenths {
        width: 20%
    }

    .tab--four-tenths,
    .tab--two-fifths {
        width: 40%
    }

    .tab--six-tenths,
    .tab--three-fifths {
        width: 60%
    }

    .tab--eight-tenths,
    .tab--four-fifths {
        width: 80%
    }

    .tab--one-sixth,
    .tab--two-twelfths {
        width: 16.666%
    }

    .tab--five-sixths,
    .tab--ten-twelfths {
        width: 83.333%
    }

    .tab--one-eighth {
        width: 12.5%
    }

    .tab--three-eighths {
        width: 37.5%
    }

    .tab--five-eighths {
        width: 62.5%
    }

    .tab--seven-eighths {
        width: 87.5%
    }

    .tab--one-tenth {
        width: 10%
    }

    .tab--three-tenths {
        width: 30%
    }

    .tab--seven-tenths {
        width: 70%
    }

    .tab--nine-tenths {
        width: 90%
    }

    .tab--one-twelfth {
        width: 8.333%
    }

    .tab--five-twelfths {
        width: 41.666%
    }

    .tab--seven-twelfths {
        width: 58.333%
    }

    .tab--eleven-twelfths {
        width: 91.666%
    }
}

@media only screen and (min-width:900px) {
    .lap--one-whole {
        width: 100%
    }

    .lap--five-tenths,
    .lap--four-eighths,
    .lap--one-half,
    .lap--six-twelfths,
    .lap--three-sixths,
    .lap--two-quarters {
        width: 50%
    }

    .lap--four-twelfths,
    .lap--one-third,
    .lap--two-sixths {
        width: 33.333%
    }

    .lap--eight-twelfths,
    .lap--four-sixths,
    .lap--two-thirds {
        width: 66.666%
    }

    .lap--one-quarter,
    .lap--three-twelfths,
    .lap--two-eighths {
        width: 25%
    }

    .lap--nine-twelfths,
    .lap--six-eighths,
    .lap--three-quarters {
        width: 75%
    }

    .lap--one-fifth,
    .lap--two-tenths {
        width: 20%
    }

    .lap--four-tenths,
    .lap--two-fifths {
        width: 40%
    }

    .lap--six-tenths,
    .lap--three-fifths {
        width: 60%
    }

    .lap--eight-tenths,
    .lap--four-fifths {
        width: 80%
    }

    .lap--one-sixth,
    .lap--two-twelfths {
        width: 16.666%
    }

    .lap--five-sixths,
    .lap--ten-twelfths {
        width: 83.333%
    }

    .lap--one-eighth {
        width: 12.5%
    }

    .lap--three-eighths {
        width: 37.5%
    }

    .lap--five-eighths {
        width: 62.5%
    }

    .lap--seven-eighths {
        width: 87.5%
    }

    .lap--one-tenth {
        width: 10%
    }

    .lap--three-tenths {
        width: 30%
    }

    .lap--seven-tenths {
        width: 70%
    }

    .lap--nine-tenths {
        width: 90%
    }

    .lap--one-twelfth {
        width: 8.333%
    }

    .lap--five-twelfths {
        width: 41.666%
    }

    .lap--seven-twelfths {
        width: 58.333%
    }

    .lap--eleven-twelfths {
        width: 91.666%
    }
}

@media only screen and (min-width:1025px) {
    .desk--one-whole {
        width: 100%
    }

    .desk--five-tenths,
    .desk--four-eighths,
    .desk--one-half,
    .desk--six-twelfths,
    .desk--three-sixths,
    .desk--two-quarters {
        width: 50%
    }

    .desk--four-twelfths,
    .desk--one-third,
    .desk--two-sixths {
        width: 33.333%
    }

    .desk--eight-twelfths,
    .desk--four-sixths,
    .desk--two-thirds {
        width: 66.666%
    }

    .desk--one-quarter,
    .desk--three-twelfths,
    .desk--two-eighths {
        width: 25%
    }

    .desk--nine-twelfths,
    .desk--six-eighths,
    .desk--three-quarters {
        width: 75%
    }

    .desk--one-fifth,
    .desk--two-tenths {
        width: 20%
    }

    .desk--four-tenths,
    .desk--two-fifths {
        width: 40%
    }

    .desk--six-tenths,
    .desk--three-fifths {
        width: 60%
    }

    .desk--eight-tenths,
    .desk--four-fifths {
        width: 80%
    }

    .desk--one-sixth,
    .desk--two-twelfths {
        width: 16.666%
    }

    .desk--five-sixths,
    .desk--ten-twelfths {
        width: 83.333%
    }

    .desk--one-eighth {
        width: 12.5%
    }

    .desk--three-eighths {
        width: 37.5%
    }

    .desk--five-eighths {
        width: 62.5%
    }

    .desk--seven-eighths {
        width: 87.5%
    }

    .desk--one-tenth {
        width: 10%
    }

    .desk--three-tenths {
        width: 30%
    }

    .desk--seven-tenths {
        width: 70%
    }

    .desk--nine-tenths {
        width: 90%
    }

    .desk--one-twelfth {
        width: 8.333%
    }

    .desk--five-twelfths {
        width: 41.666%
    }

    .desk--seven-twelfths {
        width: 58.333%
    }

    .desk--eleven-twelfths {
        width: 91.666%
    }
}

@media only screen and (min-width:1240px) {
    .large--one-whole {
        width: 100%
    }

    .large--five-tenths,
    .large--four-eighths,
    .large--one-half,
    .large--six-twelfths,
    .large--three-sixths,
    .large--two-quarters {
        width: 50%
    }

    .large--four-twelfths,
    .large--one-third,
    .large--two-sixths {
        width: 33.333%
    }

    .large--eight-twelfths,
    .large--four-sixths,
    .large--two-thirds {
        width: 66.666%
    }

    .large--one-quarter,
    .large--three-twelfths,
    .large--two-eighths {
        width: 25%
    }

    .large--nine-twelfths,
    .large--six-eighths,
    .large--three-quarters {
        width: 75%
    }

    .large--one-fifth,
    .large--two-tenths {
        width: 20%
    }

    .large--four-tenths,
    .large--two-fifths {
        width: 40%
    }

    .large--six-tenths,
    .large--three-fifths {
        width: 60%
    }

    .large--eight-tenths,
    .large--four-fifths {
        width: 80%
    }

    .large--one-sixth,
    .large--two-twelfths {
        width: 16.666%
    }

    .large--five-sixths,
    .large--ten-twelfths {
        width: 83.333%
    }

    .large--one-eighth {
        width: 12.5%
    }

    .large--three-eighths {
        width: 37.5%
    }

    .large--five-eighths {
        width: 62.5%
    }

    .large--seven-eighths {
        width: 87.5%
    }

    .large--one-tenth {
        width: 10%
    }

    .large--three-tenths {
        width: 30%
    }

    .large--seven-tenths {
        width: 70%
    }

    .large--nine-tenths {
        width: 90%
    }

    .large--one-twelfth {
        width: 8.333%
    }

    .large--five-twelfths {
        width: 41.666%
    }

    .large--seven-twelfths {
        width: 58.333%
    }

    .large--eleven-twelfths {
        width: 91.666%
    }
}

@media only screen and (min-width:1540px) {
    .wide--one-whole {
        width: 100%
    }

    .wide--five-tenths,
    .wide--four-eighths,
    .wide--one-half,
    .wide--six-twelfths,
    .wide--three-sixths,
    .wide--two-quarters {
        width: 50%
    }

    .wide--four-twelfths,
    .wide--one-third,
    .wide--two-sixths {
        width: 33.333%
    }

    .wide--eight-twelfths,
    .wide--four-sixths,
    .wide--two-thirds {
        width: 66.666%
    }

    .wide--one-quarter,
    .wide--three-twelfths,
    .wide--two-eighths {
        width: 25%
    }

    .wide--nine-twelfths,
    .wide--six-eighths,
    .wide--three-quarters {
        width: 75%
    }

    .wide--one-fifth,
    .wide--two-tenths {
        width: 20%
    }

    .wide--four-tenths,
    .wide--two-fifths {
        width: 40%
    }

    .wide--six-tenths,
    .wide--three-fifths {
        width: 60%
    }

    .wide--eight-tenths,
    .wide--four-fifths {
        width: 80%
    }

    .wide--one-sixth,
    .wide--two-twelfths {
        width: 16.666%
    }

    .wide--five-sixths,
    .wide--ten-twelfths {
        width: 83.333%
    }

    .wide--one-eighth {
        width: 12.5%
    }

    .wide--three-eighths {
        width: 37.5%
    }

    .wide--five-eighths {
        width: 62.5%
    }

    .wide--seven-eighths {
        width: 87.5%
    }

    .wide--one-tenth {
        width: 10%
    }

    .wide--three-tenths {
        width: 30%
    }

    .wide--seven-tenths {
        width: 70%
    }

    .wide--nine-tenths {
        width: 90%
    }

    .wide--one-twelfth {
        width: 8.333%
    }

    .wide--five-twelfths {
        width: 41.666%
    }

    .wide--seven-twelfths {
        width: 58.333%
    }

    .wide--eleven-twelfths {
        width: 91.666%
    }
}

[class*=push--] {
    position: relative
}

.push--zero {
    left: 0
}

.push--one-whole {
    left: 100%
}

.push--five-tenths,
.push--four-eighths,
.push--one-half,
.push--six-twelfths,
.push--three-sixths,
.push--two-quarters {
    left: 50%
}

.push--four-twelfths,
.push--one-third,
.push--two-sixths {
    left: 33.333%
}

.push--eight-twelfths,
.push--four-sixths,
.push--two-thirds {
    left: 66.666%
}

.push--one-quarter,
.push--three-twelfths,
.push--two-eighths {
    left: 25%
}

.push--nine-twelfths,
.push--six-eighths,
.push--three-quarters {
    left: 75%
}

.push--one-fifth,
.push--two-tenths {
    left: 20%
}

.push--four-tenths,
.push--two-fifths {
    left: 40%
}

.push--six-tenths,
.push--three-fifths {
    left: 60%
}

.push--eight-tenths,
.push--four-fifths {
    left: 80%
}

.push--one-sixth,
.push--two-twelfths {
    left: 16.666%
}

.push--five-sixths,
.push--ten-twelfths {
    left: 83.333%
}

.push--one-eighth {
    left: 12.5%
}

.push--three-eighths {
    left: 37.5%
}

.push--five-eighths {
    left: 62.5%
}

.push--seven-eighths {
    left: 87.5%
}

.push--one-tenth {
    left: 10%
}

.push--three-tenths {
    left: 30%
}

.push--seven-tenths {
    left: 70%
}

.push--nine-tenths {
    left: 90%
}

.push--one-twelfth {
    left: 8.333%
}

.push--five-twelfths {
    left: 41.666%
}

.push--seven-twelfths {
    left: 58.333%
}

.push--eleven-twelfths {
    left: 91.666%
}

@media only screen and (min-width:520px) {
    .push--mob--zero {
        left: 0
    }

    .push--mob--one-whole {
        left: 100%
    }

    .push--mob--five-tenths,
    .push--mob--four-eighths,
    .push--mob--one-half,
    .push--mob--six-twelfths,
    .push--mob--three-sixths,
    .push--mob--two-quarters {
        left: 50%
    }

    .push--mob--four-twelfths,
    .push--mob--one-third,
    .push--mob--two-sixths {
        left: 33.333%
    }

    .push--mob--eight-twelfths,
    .push--mob--four-sixths,
    .push--mob--two-thirds {
        left: 66.666%
    }

    .push--mob--one-quarter,
    .push--mob--three-twelfths,
    .push--mob--two-eighths {
        left: 25%
    }

    .push--mob--nine-twelfths,
    .push--mob--six-eighths,
    .push--mob--three-quarters {
        left: 75%
    }

    .push--mob--one-fifth,
    .push--mob--two-tenths {
        left: 20%
    }

    .push--mob--four-tenths,
    .push--mob--two-fifths {
        left: 40%
    }

    .push--mob--six-tenths,
    .push--mob--three-fifths {
        left: 60%
    }

    .push--mob--eight-tenths,
    .push--mob--four-fifths {
        left: 80%
    }

    .push--mob--one-sixth,
    .push--mob--two-twelfths {
        left: 16.666%
    }

    .push--mob--five-sixths,
    .push--mob--ten-twelfths {
        left: 83.333%
    }

    .push--mob--one-eighth {
        left: 12.5%
    }

    .push--mob--three-eighths {
        left: 37.5%
    }

    .push--mob--five-eighths {
        left: 62.5%
    }

    .push--mob--seven-eighths {
        left: 87.5%
    }

    .push--mob--one-tenth {
        left: 10%
    }

    .push--mob--three-tenths {
        left: 30%
    }

    .push--mob--seven-tenths {
        left: 70%
    }

    .push--mob--nine-tenths {
        left: 90%
    }

    .push--mob--one-twelfth {
        left: 8.333%
    }

    .push--mob--five-twelfths {
        left: 41.666%
    }

    .push--mob--seven-twelfths {
        left: 58.333%
    }

    .push--mob--eleven-twelfths {
        left: 91.666%
    }
}

@media only screen and (min-width:768px) {
    .push--tab-port--zero {
        left: 0
    }

    .push--tab-port--one-whole {
        left: 100%
    }

    .push--tab-port--five-tenths,
    .push--tab-port--four-eighths,
    .push--tab-port--one-half,
    .push--tab-port--six-twelfths,
    .push--tab-port--three-sixths,
    .push--tab-port--two-quarters {
        left: 50%
    }

    .push--tab-port--four-twelfths,
    .push--tab-port--one-third,
    .push--tab-port--two-sixths {
        left: 33.333%
    }

    .push--tab-port--eight-twelfths,
    .push--tab-port--four-sixths,
    .push--tab-port--two-thirds {
        left: 66.666%
    }

    .push--tab-port--one-quarter,
    .push--tab-port--three-twelfths,
    .push--tab-port--two-eighths {
        left: 25%
    }

    .push--tab-port--nine-twelfths,
    .push--tab-port--six-eighths,
    .push--tab-port--three-quarters {
        left: 75%
    }

    .push--tab-port--one-fifth,
    .push--tab-port--two-tenths {
        left: 20%
    }

    .push--tab-port--four-tenths,
    .push--tab-port--two-fifths {
        left: 40%
    }

    .push--tab-port--six-tenths,
    .push--tab-port--three-fifths {
        left: 60%
    }

    .push--tab-port--eight-tenths,
    .push--tab-port--four-fifths {
        left: 80%
    }

    .push--tab-port--one-sixth,
    .push--tab-port--two-twelfths {
        left: 16.666%
    }

    .push--tab-port--five-sixths,
    .push--tab-port--ten-twelfths {
        left: 83.333%
    }

    .push--tab-port--one-eighth {
        left: 12.5%
    }

    .push--tab-port--three-eighths {
        left: 37.5%
    }

    .push--tab-port--five-eighths {
        left: 62.5%
    }

    .push--tab-port--seven-eighths {
        left: 87.5%
    }

    .push--tab-port--one-tenth {
        left: 10%
    }

    .push--tab-port--three-tenths {
        left: 30%
    }

    .push--tab-port--seven-tenths {
        left: 70%
    }

    .push--tab-port--nine-tenths {
        left: 90%
    }

    .push--tab-port--one-twelfth {
        left: 8.333%
    }

    .push--tab-port--five-twelfths {
        left: 41.666%
    }

    .push--tab-port--seven-twelfths {
        left: 58.333%
    }

    .push--tab-port--eleven-twelfths {
        left: 91.666%
    }
}

@media only screen and (min-width:769px) {
    .push--tab--zero {
        left: 0
    }

    .push--tab--one-whole {
        left: 100%
    }

    .push--tab--five-tenths,
    .push--tab--four-eighths,
    .push--tab--one-half,
    .push--tab--six-twelfths,
    .push--tab--three-sixths,
    .push--tab--two-quarters {
        left: 50%
    }

    .push--tab--four-twelfths,
    .push--tab--one-third,
    .push--tab--two-sixths {
        left: 33.333%
    }

    .push--tab--eight-twelfths,
    .push--tab--four-sixths,
    .push--tab--two-thirds {
        left: 66.666%
    }

    .push--tab--one-quarter,
    .push--tab--three-twelfths,
    .push--tab--two-eighths {
        left: 25%
    }

    .push--tab--nine-twelfths,
    .push--tab--six-eighths,
    .push--tab--three-quarters {
        left: 75%
    }

    .push--tab--one-fifth,
    .push--tab--two-tenths {
        left: 20%
    }

    .push--tab--four-tenths,
    .push--tab--two-fifths {
        left: 40%
    }

    .push--tab--six-tenths,
    .push--tab--three-fifths {
        left: 60%
    }

    .push--tab--eight-tenths,
    .push--tab--four-fifths {
        left: 80%
    }

    .push--tab--one-sixth,
    .push--tab--two-twelfths {
        left: 16.666%
    }

    .push--tab--five-sixths,
    .push--tab--ten-twelfths {
        left: 83.333%
    }

    .push--tab--one-eighth {
        left: 12.5%
    }

    .push--tab--three-eighths {
        left: 37.5%
    }

    .push--tab--five-eighths {
        left: 62.5%
    }

    .push--tab--seven-eighths {
        left: 87.5%
    }

    .push--tab--one-tenth {
        left: 10%
    }

    .push--tab--three-tenths {
        left: 30%
    }

    .push--tab--seven-tenths {
        left: 70%
    }

    .push--tab--nine-tenths {
        left: 90%
    }

    .push--tab--one-twelfth {
        left: 8.333%
    }

    .push--tab--five-twelfths {
        left: 41.666%
    }

    .push--tab--seven-twelfths {
        left: 58.333%
    }

    .push--tab--eleven-twelfths {
        left: 91.666%
    }
}

@media only screen and (min-width:900px) {
    .push--lap--zero {
        left: 0
    }

    .push--lap--one-whole {
        left: 100%
    }

    .push--lap--five-tenths,
    .push--lap--four-eighths,
    .push--lap--one-half,
    .push--lap--six-twelfths,
    .push--lap--three-sixths,
    .push--lap--two-quarters {
        left: 50%
    }

    .push--lap--four-twelfths,
    .push--lap--one-third,
    .push--lap--two-sixths {
        left: 33.333%
    }

    .push--lap--eight-twelfths,
    .push--lap--four-sixths,
    .push--lap--two-thirds {
        left: 66.666%
    }

    .push--lap--one-quarter,
    .push--lap--three-twelfths,
    .push--lap--two-eighths {
        left: 25%
    }

    .push--lap--nine-twelfths,
    .push--lap--six-eighths,
    .push--lap--three-quarters {
        left: 75%
    }

    .push--lap--one-fifth,
    .push--lap--two-tenths {
        left: 20%
    }

    .push--lap--four-tenths,
    .push--lap--two-fifths {
        left: 40%
    }

    .push--lap--six-tenths,
    .push--lap--three-fifths {
        left: 60%
    }

    .push--lap--eight-tenths,
    .push--lap--four-fifths {
        left: 80%
    }

    .push--lap--one-sixth,
    .push--lap--two-twelfths {
        left: 16.666%
    }

    .push--lap--five-sixths,
    .push--lap--ten-twelfths {
        left: 83.333%
    }

    .push--lap--one-eighth {
        left: 12.5%
    }

    .push--lap--three-eighths {
        left: 37.5%
    }

    .push--lap--five-eighths {
        left: 62.5%
    }

    .push--lap--seven-eighths {
        left: 87.5%
    }

    .push--lap--one-tenth {
        left: 10%
    }

    .push--lap--three-tenths {
        left: 30%
    }

    .push--lap--seven-tenths {
        left: 70%
    }

    .push--lap--nine-tenths {
        left: 90%
    }

    .push--lap--one-twelfth {
        left: 8.333%
    }

    .push--lap--five-twelfths {
        left: 41.666%
    }

    .push--lap--seven-twelfths {
        left: 58.333%
    }

    .push--lap--eleven-twelfths {
        left: 91.666%
    }
}

@media only screen and (min-width:1025px) {
    .push--desk--zero {
        left: 0
    }

    .push--desk--one-whole {
        left: 100%
    }

    .push--desk--five-tenths,
    .push--desk--four-eighths,
    .push--desk--one-half,
    .push--desk--six-twelfths,
    .push--desk--three-sixths,
    .push--desk--two-quarters {
        left: 50%
    }

    .push--desk--four-twelfths,
    .push--desk--one-third,
    .push--desk--two-sixths {
        left: 33.333%
    }

    .push--desk--eight-twelfths,
    .push--desk--four-sixths,
    .push--desk--two-thirds {
        left: 66.666%
    }

    .push--desk--one-quarter,
    .push--desk--three-twelfths,
    .push--desk--two-eighths {
        left: 25%
    }

    .push--desk--nine-twelfths,
    .push--desk--six-eighths,
    .push--desk--three-quarters {
        left: 75%
    }

    .push--desk--one-fifth,
    .push--desk--two-tenths {
        left: 20%
    }

    .push--desk--four-tenths,
    .push--desk--two-fifths {
        left: 40%
    }

    .push--desk--six-tenths,
    .push--desk--three-fifths {
        left: 60%
    }

    .push--desk--eight-tenths,
    .push--desk--four-fifths {
        left: 80%
    }

    .push--desk--one-sixth,
    .push--desk--two-twelfths {
        left: 16.666%
    }

    .push--desk--five-sixths,
    .push--desk--ten-twelfths {
        left: 83.333%
    }

    .push--desk--one-eighth {
        left: 12.5%
    }

    .push--desk--three-eighths {
        left: 37.5%
    }

    .push--desk--five-eighths {
        left: 62.5%
    }

    .push--desk--seven-eighths {
        left: 87.5%
    }

    .push--desk--one-tenth {
        left: 10%
    }

    .push--desk--three-tenths {
        left: 30%
    }

    .push--desk--seven-tenths {
        left: 70%
    }

    .push--desk--nine-tenths {
        left: 90%
    }

    .push--desk--one-twelfth {
        left: 8.333%
    }

    .push--desk--five-twelfths {
        left: 41.666%
    }

    .push--desk--seven-twelfths {
        left: 58.333%
    }

    .push--desk--eleven-twelfths {
        left: 91.666%
    }
}

@media only screen and (min-width:1240px) {
    .push--large--zero {
        left: 0
    }

    .push--large--one-whole {
        left: 100%
    }

    .push--large--five-tenths,
    .push--large--four-eighths,
    .push--large--one-half,
    .push--large--six-twelfths,
    .push--large--three-sixths,
    .push--large--two-quarters {
        left: 50%
    }

    .push--large--four-twelfths,
    .push--large--one-third,
    .push--large--two-sixths {
        left: 33.333%
    }

    .push--large--eight-twelfths,
    .push--large--four-sixths,
    .push--large--two-thirds {
        left: 66.666%
    }

    .push--large--one-quarter,
    .push--large--three-twelfths,
    .push--large--two-eighths {
        left: 25%
    }

    .push--large--nine-twelfths,
    .push--large--six-eighths,
    .push--large--three-quarters {
        left: 75%
    }

    .push--large--one-fifth,
    .push--large--two-tenths {
        left: 20%
    }

    .push--large--four-tenths,
    .push--large--two-fifths {
        left: 40%
    }

    .push--large--six-tenths,
    .push--large--three-fifths {
        left: 60%
    }

    .push--large--eight-tenths,
    .push--large--four-fifths {
        left: 80%
    }

    .push--large--one-sixth,
    .push--large--two-twelfths {
        left: 16.666%
    }

    .push--large--five-sixths,
    .push--large--ten-twelfths {
        left: 83.333%
    }

    .push--large--one-eighth {
        left: 12.5%
    }

    .push--large--three-eighths {
        left: 37.5%
    }

    .push--large--five-eighths {
        left: 62.5%
    }

    .push--large--seven-eighths {
        left: 87.5%
    }

    .push--large--one-tenth {
        left: 10%
    }

    .push--large--three-tenths {
        left: 30%
    }

    .push--large--seven-tenths {
        left: 70%
    }

    .push--large--nine-tenths {
        left: 90%
    }

    .push--large--one-twelfth {
        left: 8.333%
    }

    .push--large--five-twelfths {
        left: 41.666%
    }

    .push--large--seven-twelfths {
        left: 58.333%
    }

    .push--large--eleven-twelfths {
        left: 91.666%
    }
}

@media only screen and (min-width:1540px) {
    .push--wide--zero {
        left: 0
    }

    .push--wide--one-whole {
        left: 100%
    }

    .push--wide--five-tenths,
    .push--wide--four-eighths,
    .push--wide--one-half,
    .push--wide--six-twelfths,
    .push--wide--three-sixths,
    .push--wide--two-quarters {
        left: 50%
    }

    .push--wide--four-twelfths,
    .push--wide--one-third,
    .push--wide--two-sixths {
        left: 33.333%
    }

    .push--wide--eight-twelfths,
    .push--wide--four-sixths,
    .push--wide--two-thirds {
        left: 66.666%
    }

    .push--wide--one-quarter,
    .push--wide--three-twelfths,
    .push--wide--two-eighths {
        left: 25%
    }

    .push--wide--nine-twelfths,
    .push--wide--six-eighths,
    .push--wide--three-quarters {
        left: 75%
    }

    .push--wide--one-fifth,
    .push--wide--two-tenths {
        left: 20%
    }

    .push--wide--four-tenths,
    .push--wide--two-fifths {
        left: 40%
    }

    .push--wide--six-tenths,
    .push--wide--three-fifths {
        left: 60%
    }

    .push--wide--eight-tenths,
    .push--wide--four-fifths {
        left: 80%
    }

    .push--wide--one-sixth,
    .push--wide--two-twelfths {
        left: 16.666%
    }

    .push--wide--five-sixths,
    .push--wide--ten-twelfths {
        left: 83.333%
    }

    .push--wide--one-eighth {
        left: 12.5%
    }

    .push--wide--three-eighths {
        left: 37.5%
    }

    .push--wide--five-eighths {
        left: 62.5%
    }

    .push--wide--seven-eighths {
        left: 87.5%
    }

    .push--wide--one-tenth {
        left: 10%
    }

    .push--wide--three-tenths {
        left: 30%
    }

    .push--wide--seven-tenths {
        left: 70%
    }

    .push--wide--nine-tenths {
        left: 90%
    }

    .push--wide--one-twelfth {
        left: 8.333%
    }

    .push--wide--five-twelfths {
        left: 41.666%
    }

    .push--wide--seven-twelfths {
        left: 58.333%
    }

    .push--wide--eleven-twelfths {
        left: 91.666%
    }
}

[class*=pull--] {
    position: relative
}

.pull--zero {
    right: 0
}

.pull--one-whole {
    right: 100%
}

.pull--five-tenths,
.pull--four-eighths,
.pull--one-half,
.pull--six-twelfths,
.pull--three-sixths,
.pull--two-quarters {
    right: 50%
}

.pull--four-twelfths,
.pull--one-third,
.pull--two-sixths {
    right: 33.333%
}

.pull--eight-twelfths,
.pull--four-sixths,
.pull--two-thirds {
    right: 66.666%
}

.pull--one-quarter,
.pull--three-twelfths,
.pull--two-eighths {
    right: 25%
}

.pull--nine-twelfths,
.pull--six-eighths,
.pull--three-quarters {
    right: 75%
}

.pull--one-fifth,
.pull--two-tenths {
    right: 20%
}

.pull--four-tenths,
.pull--two-fifths {
    right: 40%
}

.pull--six-tenths,
.pull--three-fifths {
    right: 60%
}

.pull--eight-tenths,
.pull--four-fifths {
    right: 80%
}

.pull--one-sixth,
.pull--two-twelfths {
    right: 16.666%
}

.pull--five-sixths,
.pull--ten-twelfths {
    right: 83.333%
}

.pull--one-eighth {
    right: 12.5%
}

.pull--three-eighths {
    right: 37.5%
}

.pull--five-eighths {
    right: 62.5%
}

.pull--seven-eighths {
    right: 87.5%
}

.pull--one-tenth {
    right: 10%
}

.pull--three-tenths {
    right: 30%
}

.pull--seven-tenths {
    right: 70%
}

.pull--nine-tenths {
    right: 90%
}

.pull--one-twelfth {
    right: 8.333%
}

.pull--five-twelfths {
    right: 41.666%
}

.pull--seven-twelfths {
    right: 58.333%
}

.pull--eleven-twelfths {
    right: 91.666%
}

@media only screen and (min-width:520px) {
    .pull--mob--zero {
        right: 0
    }

    .pull--mob--one-whole {
        right: 100%
    }

    .pull--mob--five-tenths,
    .pull--mob--four-eighths,
    .pull--mob--one-half,
    .pull--mob--six-twelfths,
    .pull--mob--three-sixths,
    .pull--mob--two-quarters {
        right: 50%
    }

    .pull--mob--four-twelfths,
    .pull--mob--one-third,
    .pull--mob--two-sixths {
        right: 33.333%
    }

    .pull--mob--eight-twelfths,
    .pull--mob--four-sixths,
    .pull--mob--two-thirds {
        right: 66.666%
    }

    .pull--mob--one-quarter,
    .pull--mob--three-twelfths,
    .pull--mob--two-eighths {
        right: 25%
    }

    .pull--mob--nine-twelfths,
    .pull--mob--six-eighths,
    .pull--mob--three-quarters {
        right: 75%
    }

    .pull--mob--one-fifth,
    .pull--mob--two-tenths {
        right: 20%
    }

    .pull--mob--four-tenths,
    .pull--mob--two-fifths {
        right: 40%
    }

    .pull--mob--six-tenths,
    .pull--mob--three-fifths {
        right: 60%
    }

    .pull--mob--eight-tenths,
    .pull--mob--four-fifths {
        right: 80%
    }

    .pull--mob--one-sixth,
    .pull--mob--two-twelfths {
        right: 16.666%
    }

    .pull--mob--five-sixths,
    .pull--mob--ten-twelfths {
        right: 83.333%
    }

    .pull--mob--one-eighth {
        right: 12.5%
    }

    .pull--mob--three-eighths {
        right: 37.5%
    }

    .pull--mob--five-eighths {
        right: 62.5%
    }

    .pull--mob--seven-eighths {
        right: 87.5%
    }

    .pull--mob--one-tenth {
        right: 10%
    }

    .pull--mob--three-tenths {
        right: 30%
    }

    .pull--mob--seven-tenths {
        right: 70%
    }

    .pull--mob--nine-tenths {
        right: 90%
    }

    .pull--mob--one-twelfth {
        right: 8.333%
    }

    .pull--mob--five-twelfths {
        right: 41.666%
    }

    .pull--mob--seven-twelfths {
        right: 58.333%
    }

    .pull--mob--eleven-twelfths {
        right: 91.666%
    }
}

@media only screen and (min-width:768px) {
    .pull--tab-port--zero {
        right: 0
    }

    .pull--tab-port--one-whole {
        right: 100%
    }

    .pull--tab-port--five-tenths,
    .pull--tab-port--four-eighths,
    .pull--tab-port--one-half,
    .pull--tab-port--six-twelfths,
    .pull--tab-port--three-sixths,
    .pull--tab-port--two-quarters {
        right: 50%
    }

    .pull--tab-port--four-twelfths,
    .pull--tab-port--one-third,
    .pull--tab-port--two-sixths {
        right: 33.333%
    }

    .pull--tab-port--eight-twelfths,
    .pull--tab-port--four-sixths,
    .pull--tab-port--two-thirds {
        right: 66.666%
    }

    .pull--tab-port--one-quarter,
    .pull--tab-port--three-twelfths,
    .pull--tab-port--two-eighths {
        right: 25%
    }

    .pull--tab-port--nine-twelfths,
    .pull--tab-port--six-eighths,
    .pull--tab-port--three-quarters {
        right: 75%
    }

    .pull--tab-port--one-fifth,
    .pull--tab-port--two-tenths {
        right: 20%
    }

    .pull--tab-port--four-tenths,
    .pull--tab-port--two-fifths {
        right: 40%
    }

    .pull--tab-port--six-tenths,
    .pull--tab-port--three-fifths {
        right: 60%
    }

    .pull--tab-port--eight-tenths,
    .pull--tab-port--four-fifths {
        right: 80%
    }

    .pull--tab-port--one-sixth,
    .pull--tab-port--two-twelfths {
        right: 16.666%
    }

    .pull--tab-port--five-sixths,
    .pull--tab-port--ten-twelfths {
        right: 83.333%
    }

    .pull--tab-port--one-eighth {
        right: 12.5%
    }

    .pull--tab-port--three-eighths {
        right: 37.5%
    }

    .pull--tab-port--five-eighths {
        right: 62.5%
    }

    .pull--tab-port--seven-eighths {
        right: 87.5%
    }

    .pull--tab-port--one-tenth {
        right: 10%
    }

    .pull--tab-port--three-tenths {
        right: 30%
    }

    .pull--tab-port--seven-tenths {
        right: 70%
    }

    .pull--tab-port--nine-tenths {
        right: 90%
    }

    .pull--tab-port--one-twelfth {
        right: 8.333%
    }

    .pull--tab-port--five-twelfths {
        right: 41.666%
    }

    .pull--tab-port--seven-twelfths {
        right: 58.333%
    }

    .pull--tab-port--eleven-twelfths {
        right: 91.666%
    }
}

@media only screen and (min-width:769px) {
    .pull--tab--zero {
        right: 0
    }

    .pull--tab--one-whole {
        right: 100%
    }

    .pull--tab--five-tenths,
    .pull--tab--four-eighths,
    .pull--tab--one-half,
    .pull--tab--six-twelfths,
    .pull--tab--three-sixths,
    .pull--tab--two-quarters {
        right: 50%
    }

    .pull--tab--four-twelfths,
    .pull--tab--one-third,
    .pull--tab--two-sixths {
        right: 33.333%
    }

    .pull--tab--eight-twelfths,
    .pull--tab--four-sixths,
    .pull--tab--two-thirds {
        right: 66.666%
    }

    .pull--tab--one-quarter,
    .pull--tab--three-twelfths,
    .pull--tab--two-eighths {
        right: 25%
    }

    .pull--tab--nine-twelfths,
    .pull--tab--six-eighths,
    .pull--tab--three-quarters {
        right: 75%
    }

    .pull--tab--one-fifth,
    .pull--tab--two-tenths {
        right: 20%
    }

    .pull--tab--four-tenths,
    .pull--tab--two-fifths {
        right: 40%
    }

    .pull--tab--six-tenths,
    .pull--tab--three-fifths {
        right: 60%
    }

    .pull--tab--eight-tenths,
    .pull--tab--four-fifths {
        right: 80%
    }

    .pull--tab--one-sixth,
    .pull--tab--two-twelfths {
        right: 16.666%
    }

    .pull--tab--five-sixths,
    .pull--tab--ten-twelfths {
        right: 83.333%
    }

    .pull--tab--one-eighth {
        right: 12.5%
    }

    .pull--tab--three-eighths {
        right: 37.5%
    }

    .pull--tab--five-eighths {
        right: 62.5%
    }

    .pull--tab--seven-eighths {
        right: 87.5%
    }

    .pull--tab--one-tenth {
        right: 10%
    }

    .pull--tab--three-tenths {
        right: 30%
    }

    .pull--tab--seven-tenths {
        right: 70%
    }

    .pull--tab--nine-tenths {
        right: 90%
    }

    .pull--tab--one-twelfth {
        right: 8.333%
    }

    .pull--tab--five-twelfths {
        right: 41.666%
    }

    .pull--tab--seven-twelfths {
        right: 58.333%
    }

    .pull--tab--eleven-twelfths {
        right: 91.666%
    }
}

@media only screen and (min-width:900px) {
    .pull--lap--zero {
        right: 0
    }

    .pull--lap--one-whole {
        right: 100%
    }

    .pull--lap--five-tenths,
    .pull--lap--four-eighths,
    .pull--lap--one-half,
    .pull--lap--six-twelfths,
    .pull--lap--three-sixths,
    .pull--lap--two-quarters {
        right: 50%
    }

    .pull--lap--four-twelfths,
    .pull--lap--one-third,
    .pull--lap--two-sixths {
        right: 33.333%
    }

    .pull--lap--eight-twelfths,
    .pull--lap--four-sixths,
    .pull--lap--two-thirds {
        right: 66.666%
    }

    .pull--lap--one-quarter,
    .pull--lap--three-twelfths,
    .pull--lap--two-eighths {
        right: 25%
    }

    .pull--lap--nine-twelfths,
    .pull--lap--six-eighths,
    .pull--lap--three-quarters {
        right: 75%
    }

    .pull--lap--one-fifth,
    .pull--lap--two-tenths {
        right: 20%
    }

    .pull--lap--four-tenths,
    .pull--lap--two-fifths {
        right: 40%
    }

    .pull--lap--six-tenths,
    .pull--lap--three-fifths {
        right: 60%
    }

    .pull--lap--eight-tenths,
    .pull--lap--four-fifths {
        right: 80%
    }

    .pull--lap--one-sixth,
    .pull--lap--two-twelfths {
        right: 16.666%
    }

    .pull--lap--five-sixths,
    .pull--lap--ten-twelfths {
        right: 83.333%
    }

    .pull--lap--one-eighth {
        right: 12.5%
    }

    .pull--lap--three-eighths {
        right: 37.5%
    }

    .pull--lap--five-eighths {
        right: 62.5%
    }

    .pull--lap--seven-eighths {
        right: 87.5%
    }

    .pull--lap--one-tenth {
        right: 10%
    }

    .pull--lap--three-tenths {
        right: 30%
    }

    .pull--lap--seven-tenths {
        right: 70%
    }

    .pull--lap--nine-tenths {
        right: 90%
    }

    .pull--lap--one-twelfth {
        right: 8.333%
    }

    .pull--lap--five-twelfths {
        right: 41.666%
    }

    .pull--lap--seven-twelfths {
        right: 58.333%
    }

    .pull--lap--eleven-twelfths {
        right: 91.666%
    }
}

@media only screen and (min-width:1025px) {
    .pull--desk--zero {
        right: 0
    }

    .pull--desk--one-whole {
        right: 100%
    }

    .pull--desk--five-tenths,
    .pull--desk--four-eighths,
    .pull--desk--one-half,
    .pull--desk--six-twelfths,
    .pull--desk--three-sixths,
    .pull--desk--two-quarters {
        right: 50%
    }

    .pull--desk--four-twelfths,
    .pull--desk--one-third,
    .pull--desk--two-sixths {
        right: 33.333%
    }

    .pull--desk--eight-twelfths,
    .pull--desk--four-sixths,
    .pull--desk--two-thirds {
        right: 66.666%
    }

    .pull--desk--one-quarter,
    .pull--desk--three-twelfths,
    .pull--desk--two-eighths {
        right: 25%
    }

    .pull--desk--nine-twelfths,
    .pull--desk--six-eighths,
    .pull--desk--three-quarters {
        right: 75%
    }

    .pull--desk--one-fifth,
    .pull--desk--two-tenths {
        right: 20%
    }

    .pull--desk--four-tenths,
    .pull--desk--two-fifths {
        right: 40%
    }

    .pull--desk--six-tenths,
    .pull--desk--three-fifths {
        right: 60%
    }

    .pull--desk--eight-tenths,
    .pull--desk--four-fifths {
        right: 80%
    }

    .pull--desk--one-sixth,
    .pull--desk--two-twelfths {
        right: 16.666%
    }

    .pull--desk--five-sixths,
    .pull--desk--ten-twelfths {
        right: 83.333%
    }

    .pull--desk--one-eighth {
        right: 12.5%
    }

    .pull--desk--three-eighths {
        right: 37.5%
    }

    .pull--desk--five-eighths {
        right: 62.5%
    }

    .pull--desk--seven-eighths {
        right: 87.5%
    }

    .pull--desk--one-tenth {
        right: 10%
    }

    .pull--desk--three-tenths {
        right: 30%
    }

    .pull--desk--seven-tenths {
        right: 70%
    }

    .pull--desk--nine-tenths {
        right: 90%
    }

    .pull--desk--one-twelfth {
        right: 8.333%
    }

    .pull--desk--five-twelfths {
        right: 41.666%
    }

    .pull--desk--seven-twelfths {
        right: 58.333%
    }

    .pull--desk--eleven-twelfths {
        right: 91.666%
    }
}

@media only screen and (min-width:1240px) {
    .pull--large--zero {
        right: 0
    }

    .pull--large--one-whole {
        right: 100%
    }

    .pull--large--five-tenths,
    .pull--large--four-eighths,
    .pull--large--one-half,
    .pull--large--six-twelfths,
    .pull--large--three-sixths,
    .pull--large--two-quarters {
        right: 50%
    }

    .pull--large--four-twelfths,
    .pull--large--one-third,
    .pull--large--two-sixths {
        right: 33.333%
    }

    .pull--large--eight-twelfths,
    .pull--large--four-sixths,
    .pull--large--two-thirds {
        right: 66.666%
    }

    .pull--large--one-quarter,
    .pull--large--three-twelfths,
    .pull--large--two-eighths {
        right: 25%
    }

    .pull--large--nine-twelfths,
    .pull--large--six-eighths,
    .pull--large--three-quarters {
        right: 75%
    }

    .pull--large--one-fifth,
    .pull--large--two-tenths {
        right: 20%
    }

    .pull--large--four-tenths,
    .pull--large--two-fifths {
        right: 40%
    }

    .pull--large--six-tenths,
    .pull--large--three-fifths {
        right: 60%
    }

    .pull--large--eight-tenths,
    .pull--large--four-fifths {
        right: 80%
    }

    .pull--large--one-sixth,
    .pull--large--two-twelfths {
        right: 16.666%
    }

    .pull--large--five-sixths,
    .pull--large--ten-twelfths {
        right: 83.333%
    }

    .pull--large--one-eighth {
        right: 12.5%
    }

    .pull--large--three-eighths {
        right: 37.5%
    }

    .pull--large--five-eighths {
        right: 62.5%
    }

    .pull--large--seven-eighths {
        right: 87.5%
    }

    .pull--large--one-tenth {
        right: 10%
    }

    .pull--large--three-tenths {
        right: 30%
    }

    .pull--large--seven-tenths {
        right: 70%
    }

    .pull--large--nine-tenths {
        right: 90%
    }

    .pull--large--one-twelfth {
        right: 8.333%
    }

    .pull--large--five-twelfths {
        right: 41.666%
    }

    .pull--large--seven-twelfths {
        right: 58.333%
    }

    .pull--large--eleven-twelfths {
        right: 91.666%
    }
}

@media only screen and (min-width:1540px) {
    .pull--wide--zero {
        right: 0
    }

    .pull--wide--one-whole {
        right: 100%
    }

    .pull--wide--five-tenths,
    .pull--wide--four-eighths,
    .pull--wide--one-half,
    .pull--wide--six-twelfths,
    .pull--wide--three-sixths,
    .pull--wide--two-quarters {
        right: 50%
    }

    .pull--wide--four-twelfths,
    .pull--wide--one-third,
    .pull--wide--two-sixths {
        right: 33.333%
    }

    .pull--wide--eight-twelfths,
    .pull--wide--four-sixths,
    .pull--wide--two-thirds {
        right: 66.666%
    }

    .pull--wide--one-quarter,
    .pull--wide--three-twelfths,
    .pull--wide--two-eighths {
        right: 25%
    }

    .pull--wide--nine-twelfths,
    .pull--wide--six-eighths,
    .pull--wide--three-quarters {
        right: 75%
    }

    .pull--wide--one-fifth,
    .pull--wide--two-tenths {
        right: 20%
    }

    .pull--wide--four-tenths,
    .pull--wide--two-fifths {
        right: 40%
    }

    .pull--wide--six-tenths,
    .pull--wide--three-fifths {
        right: 60%
    }

    .pull--wide--eight-tenths,
    .pull--wide--four-fifths {
        right: 80%
    }

    .pull--wide--one-sixth,
    .pull--wide--two-twelfths {
        right: 16.666%
    }

    .pull--wide--five-sixths,
    .pull--wide--ten-twelfths {
        right: 83.333%
    }

    .pull--wide--one-eighth {
        right: 12.5%
    }

    .pull--wide--three-eighths {
        right: 37.5%
    }

    .pull--wide--five-eighths {
        right: 62.5%
    }

    .pull--wide--seven-eighths {
        right: 87.5%
    }

    .pull--wide--one-tenth {
        right: 10%
    }

    .pull--wide--three-tenths {
        right: 30%
    }

    .pull--wide--seven-tenths {
        right: 70%
    }

    .pull--wide--nine-tenths {
        right: 90%
    }

    .pull--wide--one-twelfth {
        right: 8.333%
    }

    .pull--wide--five-twelfths {
        right: 41.666%
    }

    .pull--wide--seven-twelfths {
        right: 58.333%
    }

    .pull--wide--eleven-twelfths {
        right: 91.666%
    }
}

.slick-slider {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list,
.slick-slider {
    position: relative;
    display: block
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0)
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-selection--single .select2-selection__clear {
    position: relative
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-search--inline {
    float: left
}

.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-dropdown {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051
}

.select2-results {
    display: block
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0
}

.select2-results__option {
    padding: 6px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-results__option[aria-selected] {
    cursor: pointer
}

.select2-container--open .select2-dropdown {
    left: 0
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-search--dropdown {
    display: block;
    padding: 4px
}

.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-search--dropdown.select2-search--hide {
    display: none
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0)
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888;
    border-width: 0 4px 5px
}

.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    margin-top: 5px;
    margin-right: 10px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 2px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #000
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder {
    float: right
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #000;
    outline: 0
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: 0 0;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: #fff
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: linear-gradient(180deg, #fff 50%, #eee);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0)
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    margin-right: 10px
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: linear-gradient(180deg, #eee 50%, #ccc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0)
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: 0 0;
    border: none
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888;
    border-width: 0 4px 5px
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: linear-gradient(180deg, #fff 0, #eee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0)
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: linear-gradient(180deg, #eee 50%, #fff);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0)
}

.select2-container--classic .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 2px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    float: right;
    margin-left: 5px;
    margin-right: auto
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none
}

.select2-container--classic .select2-dropdown {
    background-color: #fff;
    border: 1px solid transparent
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none
}

.select2-container--classic .select2-dropdown--below {
    border-top: none
}

.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: #fff
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb
}

.mfp-bg {
    z-index: 1042;
    overflow: hidden;
    background: #0b0b0b;
    opacity: .8
}

.mfp-bg,
.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed
}

.mfp-wrap {
    z-index: 1043;
    outline: none !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box
}

.mfp-container:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container:before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
    display: none
}

button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}

.mfp-close:focus,
.mfp-close:hover {
    opacity: 1
}

.mfp-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
    opacity: 1
}

.mfp-arrow:after,
.mfp-arrow:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px
}

.mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto
}

.mfp-figure,
img.mfp-img {
    line-height: 0
}

.mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #444
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-height:300px),
screen and (max-width:800px) and (orientation:landscape) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media (max-width:900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        -ms-transform: scale(.75);
        transform: scale(.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        -ms-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        -ms-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.slick-slide {
    outline: none
}

.slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 0;
    position: relative
}

.slick-dots li {
    display: inline-block;
    vertical-align: middle;
    padding: 5px 9px
}

.slick-dots li button {
    display: block;
    background: hsla(0, 0%, 100%, .33);
    border-radius: 50%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    text-indent: -9999px;
    transition: .3s;


    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;


}


.slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #000;
}
.related-banners__dots{
    .slick-dots li button:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        content: '';
        border: 1px solid #fff;
        border-radius:20px;
        text-align: center;
        opacity: .8;
    }
}



.slick-dots li.slick-active button,
.slick-dots li:hover button {
    background: #fff;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.slick-dots li:first-child {
    padding-left: 0
}

.slick-arrow {
    position: absolute;
    display: block;
    width: 60px;
    height: 60px;
    top: 50%;
    padding: 0;
    margin: 0;
    border: none;
    background-color:transparent;
    transition: .4s;
    z-index: 5;
    outline: none
}

.slick-arrow:before {
    font-family: Material Icons;
    font-size:  60px;
    text-align: center;
    transition: .4s;
    color: #fff
}

.slick-arrow:hover {
    background-color: transparent;
}

.slick-arrow:hover:before {
    color: $grey
}

.slick-arrow.slick-prev {
    left: -20px;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%)
}

.slick-arrow.slick-prev:before {
    content: "\E314"
}

.slick-arrow.slick-next {
    right: -20px;
    -webkit-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    transform: translate(100%, -50%)
}

.slick-arrow.slick-next:before {
    content: "\E315"
}

.select2-container .select2-selection--single {
    height: 100%;
    border-radius: 0;
    border-style: none;
    outline: none;
    box-shadow: 0 0 7px rgba(0, 0, 0, .22)
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 20px 30px;
    color: $moderna;
    font-size: 16px;
    line-height: 17px;
    font-family: Averta-Thin, sans-serif;
    text-align: left
}

html.is-ie .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-family: Averta-Regular, sans-serif
}

.select2-container {
    outline: none
}

.select2-dropdown {
    border: none;
    border-radius: 0
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    color: $moderna;
    font-size: 16px;
    font-family: Averta-Thin, sans-serif
}

html.is-ie .select2-container--default .select2-search--dropdown .select2-search__field {
    font-family: Averta-Regular, sans-serif
}

.select2-results__option {
    padding: 15px 30px
}

.select2-results__option[aria-selected] {
    font-family: Averta-Thin, sans-serif;
    font-size: 16px;
    color: $moderna
}

html.is-ie .select2-results__option[aria-selected] {
    font-family: Averta-Regular, sans-serif
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #fff;
    color: $moderna
}

.select2-container--default .select2-results__option[aria-selected=true] {
    color: $moderna;
    background-color: #fff;
    opacity: .5
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected]:hover,
.select2-results__option:hover {
    color: $moderna;
    background-color: #ddd
}

.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[aria-selected=true]:hover {
    background: #ddd
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    display: none
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-family: Averta-Thin, sans-serif;
    font-size: 16px;
    color: $moderna
}

html.is-ie .select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-family: Averta-Regular, sans-serif
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 50%;
    right: 15px;
    width: 24px;
    height: 24px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.select2-container--default .select2-selection--single .select2-selection__arrow:after {
    content: "\E313";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotateX(0deg);
    transform: translate(-50%, -50%) rotateX(0deg);
    color: #c8c8c8;
    font-size: 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:after {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg);
    transform: translate(-50%, -50%) rotateX(-180deg)
}

.select2-container--open .select2-dropdown--below {
    box-shadow: 0 4px 5px 1px rgba(0, 0, 0, .22);
    width: 100%
}

html.is-ie #ccc #ccc-content,
html.is-ie #ccc #ccc-notify,
html.is-ie #ccc .ccc-notify-button {
    font-family: Averta-Regular, sans-serif !important
}

html #ccc {
    z-index: 15 !important
}

html #ccc #ccc-icon {
    display: none !important
}

html #ccc #ccc-title {
    color: $moderna !important;
    margin-bottom: 10px !important;
    font-size: 28px !important;
    line-height: 32px !important;
    font-weight: 400 !important
}

html #ccc #ccc-content,
html #ccc #ccc-notify {
    font-family: Averta-Thin, sans-serif !important;
    font-size: 16px !important
}

html #ccc #ccc-content {
    padding: 24px 20px !important
}

@media (min-width:769px) {
    html #ccc #ccc-content {
        padding: 24px !important
    }
}

html #ccc #ccc-notify {
    display: block !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, .33) !important
}

@media (min-width:769px) {
    html #ccc #ccc-notify {
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
        -webkit-align-items: center !important;
        -ms-flex-align: center !important;
        align-items: center !important
    }
}

@media (min-width:1200px) {
    html #ccc #ccc-notify {
        padding: 10px 100px !important
    }
}

html #ccc .ccc-notify-buttons {
    position: static !important;
    margin-top: 16px !important;
    white-space: normal !important
}

@media (min-width:769px) {
    html #ccc .ccc-notify-buttons {
        position: relative !important;
        margin-top: 0 !important;
        white-space: nowrap !important;
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }
}

html #ccc #ccc-content {
    border-radius: 0 !important
}

html #ccc #ccc-content #ccc-close svg path {
    fill: $moderna !important
}

html #ccc #ccc-statement a {
    color: $moderna !important;
    text-decoration: none !important
}

html #ccc #ccc-content #ccc-recommended-settings {
    background: rgba(102, 150, 204, .22) !important;
    color: $moderna !important;
    margin: 0 !important;
    display: none !important
}

html #ccc #ccc-content #ccc-necessary-title,
html #ccc #ccc-content #ccc-title {
    color: $moderna !important;
    font-family: Averta-Black, sans-serif !important;
    font-size: 24px !important;
    font-weight: 400 !important
}

@media (min-width:769px) {
    html #ccc #ccc-notify .ccc-notify-text {
        padding-right: 10px
    }
}

html #ccc #ccc-notify .ccc-notify-text h3 {
    color: $moderna !important;
    font-family: Averta-Black, sans-serif !important;
    font-size: 24px !important;
    margin: 0 0 5px !important;
    font-weight: 400 !important;
    line-height: 28px !important
}

html #ccc #ccc-notify p {
    opacity: 1 !important;
    color: $moderna !important
}

html #ccc .ccc-notify-button {
    position: relative;
    display: inline-block;
    font-size: 15px !important;
    text-decoration: none;
    padding: 15px 20px !important;
    text-align: center;
    margin: 5px 0 !important;
    box-shadow: 0 0 1px transparent;
    background: rgba(102, 150, 204, .22) !important;
    color: $moderna !important;
    border: none !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
    text-transform: uppercase;
    width: 100% !important;
    font-family: Averta-Thin, sans-serif !important
}

@media (min-width:769px) {
    html #ccc .ccc-notify-button {
        width: auto !important;
        padding: 15px 28px !important;
        margin: 5px !important
    }
}

html #ccc .ccc-notify-button.ccc-button-solid {
    background: $moderna !important;
    color: #fff !important
}

html #ccc #ccc-notify-dismiss {
    position: absolute !important;
    top: 20px !important;
    right: 0 !important
}

@media (min-width:769px) {
    html #ccc #ccc-notify-dismiss {
        position: relative !important;
        top: 6px !important;
        right: auto !important
    }
}

html #ccc #ccc-notify-dismiss svg path {
    fill: $moderna !important
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400
}

hr {
    width: 100%;
    height: 1px;
    border-top: 1px solid #dedede;
    margin: 60px 0
}

.site,
.site__body {
    margin: 0;
    padding: 0;
    height: 100%
}

.site__body {
    width: 100%;
    font-family: Averta-Thin, sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
    color: #000;
    background-color: #fff
}

html.is-ie .site__body {
    font-family: Averta-Regular, sans-serif
}

.site__main {
    padding-top: 75px
}

@media (min-width:769px) {
    .site__main {
        padding-top: 0
    }
}

@media (min-width:769px) {
    .site__main-body {
        position: relative;
        z-index: 1;
        transition: -webkit-transform .8s cubic-bezier(.59, .01, .28, 1);
        transition: transform .8s cubic-bezier(.59, .01, .28, 1);
        transition: transform .8s cubic-bezier(.59, .01, .28, 1), -webkit-transform .8s cubic-bezier(.59, .01, .28, 1);
        background: #fff;
        margin-top: 0px;
        -webkit-transform: translateY(0) translateZ(0) !important;
        transform: translateY(0) translateZ(0) !important
    }

    .scrolled .site__main-body {
        -webkit-transform: translateY(0) translateZ(0) !important;
        transform: translateY(0) translateZ(0) !important
    }
}

.site__no-scroll {
    overflow: hidden
}
c
.container,
.container-large,
.container-medium,
.container-slider,
.container-small,
.error-page__container,
.glance__wrapper {
    width: 100%;
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto
}
.container-full{
  max-width: 100%;
}
.container-large {
    max-width: 1540px
}

.container-medium {
    max-width: 1060px
}

.container-small {
    max-width: 880px
}

.container-slider {
    max-width: 1400px
}

@media (min-width:768px) {
    .container-slider {
        padding: 0 100px
    }
}

.section__title {
    font-size: 28px;
    line-height: 38px;
    color: $moderna;
    margin-bottom: 35px
}

@media (min-width:769px) {
    .section__title {
        font-size: 48px;
        line-height: 58px
    }
}

[data-animate-title] {
    opacity: 0
}

[data-animate-title] span {
    display: inline-block;
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    transition: opacity, -webkit-transform;
    transition: opacity, transform;
    transition: opacity, transform, -webkit-transform;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(.645, .045, .355, 1)
}

[data-animate-title].rendered {
    opacity: 1
}

.in-view [data-animate-title] span {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition-timing-function: cubic-bezier(.645, .045, .355, 1)
}

.in-view [data-animate-title] span:first-child {
    transition-delay: .12s
}

.in-view [data-animate-title] span:nth-child(2) {
    transition-delay: .24s
}

.in-view [data-animate-title] span:nth-child(3) {
    transition-delay: .36s
}

.in-view [data-animate-title] span:nth-child(4) {
    transition-delay: .48s
}

.in-view [data-animate-title] span:nth-child(5) {
    transition-delay: .6s
}

.in-view [data-animate-title] span:nth-child(6) {
    transition-delay: .72s
}

.in-view [data-animate-title] span:nth-child(7) {
    transition-delay: .84s
}

.in-view [data-animate-title] span:nth-child(8) {
    transition-delay: .96s
}

.in-view [data-animate-title] span:nth-child(9) {
    transition-delay: 1.08s
}

.in-view [data-animate-title] span:nth-child(10) {
    transition-delay: 1.2s
}

.in-view [data-animate-title] span:nth-child(11) {
    transition-delay: 1.32s
}

.in-view [data-animate-title] span:nth-child(12) {
    transition-delay: 1.44s
}

.in-view [data-animate-title] span:nth-child(13) {
    transition-delay: 1.56s
}

.in-view [data-animate-title] span:nth-child(14) {
    transition-delay: 1.68s
}

.in-view [data-animate-title] span:nth-child(15) {
    transition-delay: 1.8s
}

.in-view [data-animate-title] span:nth-child(16) {
    transition-delay: 1.92s
}

.in-view [data-animate-title] span:nth-child(17) {
    transition-delay: 2.04s
}

.in-view [data-animate-title] span:nth-child(18) {
    transition-delay: 2.16s
}

.in-view [data-animate-title] span:nth-child(19) {
    transition-delay: 2.28s
}

.in-view [data-animate-title] span:nth-child(20) {
    transition-delay: 2.4s
}

.in-view [data-animate-title] span:nth-child(21) {
    transition-delay: 2.52s
}

.in-view [data-animate-title] span:nth-child(22) {
    transition-delay: 2.64s
}

.in-view [data-animate-title] span:nth-child(23) {
    transition-delay: 2.76s
}

.in-view [data-animate-title] span:nth-child(24) {
    transition-delay: 2.88s
}

.in-view [data-animate-title] span:nth-child(25) {
    transition-delay: 3s
}

.in-view [data-animate-title] span:nth-child(26) {
    transition-delay: 3.12s
}

.in-view [data-animate-title] span:nth-child(27) {
    transition-delay: 3.24s
}

.in-view [data-animate-title] span:nth-child(28) {
    transition-delay: 3.36s
}

.in-view [data-animate-title] span:nth-child(29) {
    transition-delay: 3.48s
}

.in-view [data-animate-title] span:nth-child(30) {
    transition-delay: 3.6s
}

[data-animate-content] {
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    transition: .5s .3s
}

.in-view [data-animate-content] {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.promises__title,
.section__content h1,
.section__content h2,
.section__content h3 h4,
.section__content h5,
.section__content h6,
.section__subtitle {
    font-family: Averta-Black, sans-serif;
    font-size: 21px;
    line-height: 25px;
    color: $moderna;
    margin-bottom: 30px
}
.contact__list-item{
    .section__subtitle{
        @media (min-width:769px) {
            font-size: 24px;
            line-height: 29px;
        }

    }
}

@media (min-width:1200px) {

    .promises__title,
    .section__content h1,
    .section__content h2,
    .section__content h3 h4,
    .section__content h5,
    .section__content h6,
    .section__subtitle {
        font-size: 28px;
        line-height: 36px
    }
}

.section__content {
    font-size: 16px;
    font-family: Averta-Thin, sans-serif;
    color: #000;
    margin-bottom: 60px
}

html.is-ie .section__content {
    font-family: Averta-Regular, sans-serif
}

.section__content h1,
.section__content h2,
.section__content h3 h4,
.section__content h5,
.section__content h6 {
    font-size: 28px;
    line-height: 32px
}

.section__content ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.section__content ul li {
    position: relative;
    color: $moderna;
    padding-left: 15px;
    margin-bottom: 25px
}

.section__content ul li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $moderna
}

.section__content a {
    color: $moderna;
    text-decoration: none
}

.link,
.link--blue-soft,
.link--cyan-hard,
.link--white {
    font-size: 18px;
    text-decoration: none
}

.link--cyan-hard {
    color: $moderna
}

.link--blue-soft {
    color: $moderna
}

.link--white {
    color: #fff
}

.cta,
.cta--cyan,
.cta--grey,
.cta--orange,
.cta--white {
    position: relative;
    display: inline-block;
    font-size: 15px;
    text-decoration: none;
    padding: 16px 30px;
    text-align: center;
    margin: 10px 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px transparent;
    overflow: hidden;
    border: none;
}

.cta--cyan:after,
.cta--cyan:before,
.cta--grey:after,
.cta--grey:before,
.cta--orange:after,
.cta--orange:before,
.cta--white:after,
.cta--white:before,
.cta:after,
.cta:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 100%;
    left: 0;
    z-index: -1;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    transition-timing-function: cubic-bezier(.75, 0, .125, 1)
}

.cta--cyan span,
.cta--grey span,
.cta--orange span,
.cta--white span,
.cta span {
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s
}

.cta--cyan:hover:before,
.cta--grey:hover:before,
.cta--orange:hover:before,
.cta--white:hover:before,
.cta:hover:before {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}

.cta--cyan:hover:after,
.cta--grey:hover:after,
.cta--orange:hover:after,
.cta--white:hover:after,
.cta:hover:after {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    transition-delay: .175s
}


a:hover,
a:focus,
a:visited,
a:active{
    text-decoration:none;
}
@media (min-width:769px) {

    .cta,
    .cta--cyan,
    .cta--grey,
    .cta--orange,
    .cta--white {
        font-size: 18px
    }
}

.cta--orange {
    background-color: rgba(255, 142, 43, .2)
}

.cta--orange:before {
    background: rgba(255, 142, 43, .3)
}

.cta--orange:after {
    background: rgba(255, 142, 43, .4)
}

.cta--orange span {
    color: $grey
}

.cta--orange:hover span {
    color: #fff
}

.cta--grey {
    background-color: rgba(0, 0, 0, .11)
}

.cta--grey:before {
    background-color: rgba(0, 0, 0, .2)
}

.cta--grey:after {
    background-color: rgba(0, 0, 0, .3)
}

.cta--grey span {
    color: $light_grey
}

.cta--grey:hover span {
    color: #fff
}

.cta--white {
    background-color: hsla(0, 0%, 100%, .3)
}

.cta--white:before {
    background: hsla(0, 0%, 100%, .4)
}

.cta--white:after {
    background: hsla(0, 0%, 100%, .5)
}

.cta--white span {
    color: #fff
}

.cta--white:hover span {
    color: $moderna
}

.cta--cyan {
    background-color: rgba(102, 150, 204, .2)
}

.cta--cyan:before {
    background: rgba(102, 150, 204, .3)
}

.cta--cyan:after {
    background: rgba(102, 150, 204, .4)
}

.cta--cyan span {
    color: $moderna
}

.cta--cyan:hover span {
    color: #fff
}

.cta--full {
    width: 100%
}

.c_white {
    color: #fff
}

.table {
    display: table;
    width: 100%;
    border-collapse: collapse
}

.table .thead {
    display: none
}

@media (min-width:769px) {
    .table .thead {
        display: table-header-group;
        border-bottom: 2px solid #dedede
    }

    .table .thead .tr:hover {
        box-shadow: none;
        border-bottom: 2px solid #dedede
    }
}

.table .tr {
    text-decoration: none;
    display: block;
    margin-bottom: 30px
}

@media (min-width:769px) {
    .table .tr {
        display: table-row;
        margin-bottom: 0;
        border-bottom: 1px solid #dedede;
        transition: .4s
    }

    .table .tr:hover {
        box-shadow: 0 0 3px rgba(0, 0, 0, .22);
        border-bottom: 1px solid transparent
    }

    .table .tr:hover>.td {
        color: $moderna
    }
}

.table .tr>.td:last-child {
    border-bottom: none
}

.table .td,
.table .th {
    display: block
}

@media (min-width:769px) {

    .table .td,
    .table .th {
        display: table-cell;
        text-align: right
    }

    .table .td:first-child,
    .table .th:first-child {
        text-align: left
    }
}

.table .th {
    font-size: 12px;
    color: $moderna;
    padding: 10px;
    min-width: 140px
}

.table .th:first-child {
    width: 40%
}

.table .td {
    color: $light_grey;
    padding: 5px 0;
    border-bottom: 1px solid #dedede;
    transition: .4s;
    *zoom: 1
}

.table .td:after,
.table .td:before {
    content: " ";
    display: table
}

.table .td:after {
    clear: both
}

@media (min-width:769px) {
    .table .td {
        padding: 25px 10px;
        border-bottom: none
    }
}

.table .td:first-child {
    border-bottom: 2px solid #dedede;
    color: $moderna
}

@media (min-width:769px) {
    .table .td:first-child {
        border-bottom: none
    }
}

.table .td span {
    float: left;
    width: calc(100% - 130px)
}

@media (min-width:769px) {
    .table .td span {
        float: none;
        width: auto
    }
}

.table .td:before {
    content: attr(data-label);
    width: 130px;
    float: left
}

@media (min-width:769px) {
    .table .td:before {
        display: none
    }
}

.figure__caption {
    display: inline-block;
    width: 30%;
    color: $moderna;
    font-family: Averta-Black, sans-serif;
    font-size: 18px;
    margin-right: 30px
}

.figure__description {
    display: inline-block;
    color: #777;
    font-family: Averta-Thin, sans-serif;
    font-size: 14px
}

html.is-ie .figure__description {
    font-family: Averta-Regular, sans-serif
}

.moz-background-picture:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #eee;
    z-index: 9
}

.moz-background-picture.lazyloaded:before {
    display: none
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
    height: 75px;
    transition: .6s;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .11)
}

@media (min-width:769px) {
    .header {
        height: 80px;
        background: hsla(0, 0%, 100%, .77)
    }
}

.no-hero .header,
.scrolled .header {
    background: #fff
}

@media (min-width:769px) {
    .page-template-contact.no-hero .header {
        background: hsla(0, 0%, 100%, .77)
    }
}

@media (min-width:769px) {
    .page-template-contact.no-hero.header--white .header {
        background: #fff
    }
}

@media (min-width:1025px) {
    .fixed-banner--visible .header {
        -webkit-transform: translateY(112px);
        -ms-transform: translateY(112px);
        transform: translateY(112px)
    }
}

.header__container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

.header__logo {
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;
    line-height: 1;
}

.header__logo svg {
    width: 105px;
    height: auto
}

@media (min-width:769px) {
    .header__logo svg {
        width: 140px
    }
}

.header__menu {
    position: fixed;
    top: 75px;
    left: 0;
    width: 100%;
    height: calc(100vh - 75px);
    background: $moderna;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 8
}

.menu-open .header__menu {
    opacity: 1;
    visibility: visible
}

@media (min-width:769px) {
    .header__menu {
        top: 80px;
        height: calc(100vh - 100px)
    }
}

@media (min-width:1025px) {
    .header__menu {
        position: relative;
        top: auto;
        left: auto;
        background: 0 0;
        opacity: 1;
        visibility: visible;
        text-align: center;
        width: 100%;
        height: 100%
    }
}

.header__holder {
    min-height: 100%;
    position: relative
}

@media (min-width:1025px) {
    .header__holder {
        height: 100%
    }
}

.header__inner {
    position: relative;
    min-height: calc(100vh - 75px)
}

.header__inner:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .33);
    display: none
}

.submenu-triggered .header__inner:after {
    display: block
}

@media (min-width:1025px) {
    .submenu-triggered .header__inner:after {
        display: none
    }
}

@media (min-width:769px) {
    .header__inner {
        min-height: calc(100vh - 100px)
    }
}

@media (min-width:1025px) {
    .header__inner {
        transition: none;
        height: 100%;
        min-height: 100%
    }
}

.header__burger {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    line-height: 0
}

@media (min-width:1025px) {
    .header__burger {
        display: none
    }
}

.burgersvg {
    width: 50px;
        height: 50px;
    cursor: pointer;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    path {
        fill: none;
        -webkit-transition: stroke-dashoffset .5s cubic-bezier(.25,-.25,.75,1.25),stroke-dasharray .5s cubic-bezier(.25,-.25,.75,1.25);
        -moz-transition: stroke-dashoffset .5s cubic-bezier(.25,-.25,.75,1.25),stroke-dasharray .5s cubic-bezier(.25,-.25,.75,1.25);
        -o-transition: stroke-dashoffset .5s cubic-bezier(.25,-.25,.75,1.25),stroke-dasharray .5s cubic-bezier(.25,-.25,.75,1.25);
        -ms-transition: stroke-dashoffset .5s cubic-bezier(.25,-.25,.75,1.25),stroke-dasharray .5s cubic-bezier(.25,-.25,.75,1.25);
        transition: stroke-dashoffset .5s cubic-bezier(.25,-.25,.75,1.25),stroke-dasharray .5s cubic-bezier(.25,-.25,.75,1.25);
        stroke-width: 25px;
        stroke: $grey;
        stroke-dashoffset: 0;
    }
    path#middle {
        stroke-dasharray: 240px 240px;
    }
    path#bottom,path#top {
        stroke-dasharray: 240px 950px;
    }
}


.menu-open {
    .burgersvg {
        path#bottom,path#top {
            stroke-dashoffset: -650px;
        }
        path#middle {
            stroke-dashoffset: -115px;
            stroke-dasharray: 1px 220px;
        }
    }

}

.header__burger-text {
    color: $moderna;
    margin-right: 5px;
    line-height: 20px;
    margin-top: 2px
}

.header__burger-btn,
.header__burger-text {
    display: inline-block;
    vertical-align: middle
}

.header__burger-btn {
    height: 19px;
    width: 25px;
    padding: 0;
    margin: 0;
    top: 0;
    position: relative;
    border: none;
    transition: .4s
}

.header__burger-btn span {
    height: 3px;
    top: 50%;
    width: 100%;
    left: 50%;
    position: absolute;
    background-color: $moderna;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    visibility: visible;
    transition: background-color .4s
}

.header__burger-btn:after,
.header__burger-btn:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 25px;
    height: 3px;
    left: 50%;
    background-color: $moderna;
    transition: all .4s
}

.header__burger-btn:before {
    -webkit-transform: rotate(0deg) translate(-50%, -8px);
    -ms-transform: rotate(0deg) translate(-50%, -8px);
    transform: rotate(0deg) translate(-50%, -8px)
}

.header__burger-btn:after {
    -webkit-transform: rotate(0deg) translate(-50%, 8px);
    -ms-transform: rotate(0deg) translate(-50%, 8px);
    transform: rotate(0deg) translate(-50%, 8px)
}

.menu-open .header__burger-btn span {
    visibility: hidden
}

.menu-open .header__burger-btn:before {
    -webkit-transform: translate(-50%) rotate(-45deg);
    -ms-transform: translate(-50%) rotate(-45deg);
    transform: translate(-50%) rotate(-45deg)
}

.menu-open .header__burger-btn:after {
    -webkit-transform: translate(-50%) rotate(45deg);
    -ms-transform: translate(-50%) rotate(45deg);
    transform: translate(-50%) rotate(45deg)
}

.menu--main {
    height: 100%;
    text-align: left;
    transition: .5s;
    transition-delay: .5s
}

.menu-secondary--visible .menu--main {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    transition-delay: 0s
}

@media (min-width:1025px) {
    .menu--main {
        text-align: center
    }
}

.menu--main .menu__list {
    margin: 0;
    padding: 0;
    list-style: none
}

@media (min-width:1025px) {
    .menu--main .menu__list:hover>.menu__item>.menu__link {
        color: rgba(2, 63, 125, .5)
    }
}

.menu--main .menu__list--level-0 {
    height: calc(100vh - 70px - 75px);
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    padding: 50px 20px
}

.menu--main .menu__list--level-0 .menu__item {
    display: block
}

@media (min-width:1025px) {
    .menu--main .menu__list--level-0.current-item-exists>.menu__item>.menu__link {
        color: rgba(2, 63, 125, .6)
    }
}

@media (min-width:769px) {
    .menu--main .menu__list--level-0 {
        height: calc(100vh - 70px - 100px)
    }
}

@media (min-width:1025px) {
    .menu--main .menu__list--level-0 {
        height: 100%;
        position: relative;
        display: table;
        margin: 0 auto;
        padding: 0;
        overflow: inherit
    }
    .menu--main .menu__list--level-0:after {
        display: none
    }
    .menu--main .menu__list--level-0 .menu__item {
        display: table-cell;
        vertical-align: middle
    }
}

.menu--main .menu__list--submenu {
    display: inline-block;
    margin: 0 auto
}

.menu--main .menu__list--wrapper {
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    width: calc(100% - 50px);
    opacity: 0;
    padding: 50px 20px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    background: $moderna;
    transition: opacity .8s, height 0s .8s, -webkit-transform .8s;
    transition: opacity .8s, transform .8s, height 0s .8s;
    transition: opacity .8s, transform .8s, height 0s .8s, -webkit-transform .8s;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, .33);
    z-index: 5
}

.menu--main .menu__list--wrapper.active-list {
    height: calc(100vh - 75px);
    transition: opacity .8s, height 0s 0s, -webkit-transform .8s;
    transition: opacity .8s, transform .8s, height 0s 0s;
    transition: opacity .8s, transform .8s, height 0s 0s, -webkit-transform .8s
}

@media (min-width:769px) {
    .menu--main .menu__list--wrapper.active-list {
        height: calc(100vh - 100px)
    }
}

@media (min-width:1025px) {
    .menu--main .menu__list--wrapper {
        top: 100%;
        left: 50%;
        padding: 20px 0;
        background: hsla(0, 0%, 100%, .77);
        -webkit-transform: scaleY(0) translateX(-50%);
        -ms-transform: scaleY(0) translateX(-50%);
        transform: scaleY(0) translateX(-50%);
        -webkit-transform-origin: top center;
        -ms-transform-origin: top center;
        transform-origin: top center;
        transition: .7s;
        z-index: 7;
        min-height: auto;
        box-shadow: none;
        width: auto;
        min-width: 300px;
        opacity: 1;
        height: auto;
        overflow: inherit;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-top: 1px solid #ccc
    }
}

@media (min-width:1025px) {

    .no-hero .menu--main .menu__list--wrapper,
    .scrolled .menu--main .menu__list--wrapper {
        background: #fff
    }
}

@media (min-width:1025px) {
    .menu--main .menu__list--wrapper .menu__list.current-item-in-list>.menu__item>.menu__link {
        color: rgba(2, 63, 125, .6)
    }
}

@media (min-width:1025px) {
    .menu--main .menu__list--wrapper .menu__list {
        opacity: 0;
        -webkit-transform: translateY(30px);
        -ms-transform: translateY(30px);
        transform: translateY(30px);
        transition-duration: 0s;
        transition-delay: 0s
    }
}

.menu--main .menu__list--wrapper .menu__item {
    display: block
}

@media (min-width:1025px) {
    .menu--main .menu__list--wrapper .menu__item {
        display: block;
        vertical-align: middle
    }
}

@media (min-width:1025px) {
    .menu--main .menu__list--wrapper.submenu-open {
        -webkit-transform: scaleY(1) translateX(-50%);
        -ms-transform: scaleY(1) translateX(-50%);
        transform: scaleY(1) translateX(-50%);
    }

    .menu--main .menu__list--wrapper.submenu-open .menu__list {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        transition-duration: .5s;
        transition-delay: .7s
    }
}

.menu--main .menu__item {
    padding: 5px 10px
}

@media (min-width:1025px) {
    .menu--main .menu__item {
        padding: 5px 15px
    }
}

.menu--main .menu__item--ancestor>.menu__link,
.menu--main .menu__item--current>.menu__link,
.menu--main .menu__item:hover>.menu__link {
    color: #fff
}

@media (min-width:1025px) {

    .menu--main .menu__item--ancestor>.menu__link,
    .menu--main .menu__item--current>.menu__link,
    .menu--main .menu__item:hover>.menu__link {
        color: $moderna !important
    }
}

.menu--main .menu__item--ancestor.menu__item--has-children .menu__link:after,
.menu--main .menu__item--current.menu__item--has-children .menu__link:after,
.menu--main .menu__item:hover.menu__item--has-children .menu__link:after {
    color: #fff
}

.menu--main .menu__item.menu__item--has-children>.menu__link {
    position: relative;
    display: block
}

.menu--main .menu__item.menu__item--has-children>.menu__link:after {
    content: "\E315";
    position: absolute;
    top: 50%;
    right: -10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: Material Icons;
    color: hsla(0, 0%, 100%, .5);
    margin-left: 5px;
    font-size: 20px;
    transition: .4s
}

@media (min-width:1025px) {
    .menu--main .menu__item.menu__item--has-children>.menu__link {
        position: relative;
    }

    .menu--main .menu__item.menu__item--has-children>.menu__link:after {
        display: none
    }
}

.menu--main .menu__link {
    font-size: 22px;
    line-height: 34px;
    text-decoration: none;
    color: hsla(0, 0%, 100%, .5);
    position:relative;

}

.menu--main .menu__item{
    transition: .4s;
    position:relative;
    min-width: 110px;
    @media (max-width:1200px) {
         min-width: 70px;
    }
    .menu--main .menu__item
    .menu__link{
        transition-delay: 0.4s;
        
    }
    &:after {
         position: absolute;
          bottom: 0px;
          left: 0;
          width: 0%;
          content: "";
          background: $moderna;
          height: 2px;
          transition: 0.4s;
    }

    &:before {
         position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
          opacity: 0;
          top: 100%;
          content: "";
          color: transparent;
          background: $moderna;
         transition:all 0.4s;
         transition-delay: 0.4s;
    }
    &.menu__item--current,
    &:hover{
        .menu__link{
         color: #fff!important;
         z-index: 1;
        }
        &:before {
             top: 0;
             opacity: 1;
        }
        &:after {
            width: 100%;
        }
    }
}


.menu__list--wrapper{
    .menu__item{
        .menu__link{
             transition-delay: 0s;
              color: $moderna!important;
        }
        
         &:before {
            display: none;
         }

         &:hover{
            .menu__link{
                color: $moderna!important;
            }
            
         }
    }
}

.menu--main .menu__item:hover .menu__list--wrapper .menu__link {
    color: $moderna!important;
    z-index: 1;
}


@media (min-width:1025px) {
    .menu--main .menu__link {
        font-size: 16px;
        line-height: 28px;
        color: $moderna
    }
}


@media (max-width:1200px)  and (min-width:1025px) {
     .menu--main .menu__link {
       font-size: 14px!important;
    }
    
}

.overflow-hidden {
    overflow: hidden !important
}



@media (min-width:1025px) {
    .menu--login .menu__link {
        color: $moderna;
    }
}

.footer {
    position: relative;
    width: 100%
}

.footer__top {
    background: $moderna;
    padding: 45px 0
}

@media (min-width:769px) {
    .footer__top {
        padding: 70px 0
    }
}

.footer__bottom {
    background: $grey;
    padding: 45px 0;
    position: relative
}

@media (min-width:769px) {
    .footer__bottom {
        padding: 70px 0 80px
    }
}

.footer__menu {
    margin-bottom: 45px
}

@media (min-width:769px) {
    .footer__menu {
        margin-bottom: 70px
    }
}

.footer__info {
    text-align: center
}

@media (min-width:769px) {
    .footer__info {
        text-align: left;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end
    }
}

.footer__logo {
    display: block;
    margin-bottom: 20px;
    svg{
        max-height: 52px;
    }
}



@media (min-width:769px) {
    .footer__logo {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }
}

@media (max-width:768px) {
    .footer__logo svg {
        width: 155px
    }
}
@media (min-width:769px) {
    .footer__logo svg {
        width: 100px
    }
}

@media (min-width:1025px) {
    .footer__logo svg {
        width: 155px
    }
}

.footer__logo span {
    font-size: 12px;
    color: gray;
    display: block
}

.footer__contact-info {
    display: block;
    margin-bottom: 20px
}

@media (min-width:769px) {
    .footer__contact-info {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }
}

.footer__bottom-menu {
    display: block
}

@media (min-width:769px) {
    .footer__bottom-menu {
        -webkit-flex: 2;
        -ms-flex: 2;
        flex: 2;
        text-align: center
    }
}

.footer__bottom-menu-wrapper {
    display: inline-block;
    text-align: left
}

.footer__tel {
    display: block;
    text-decoration: none;
    color: #fff;
    margin-bottom: 5px
}

.footer__address {
    color: #fff;
    display: block;
    font-style: normal;
    padding:0;
    margin:0;
}
.footer__address p{
   padding:0;
   margin: 0;
   color: #fff;
}
.footer__address a{
   color: #fff;
}
.footer__address a:hover{
   color: $moderna;
}
.footer__additional-info {
    display: block;
    margin-bottom: 30px
}

@media (min-width:769px) {
    .footer__additional-info {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }
}

.footer__copyright {
    color: #fff;
    margin-top: 5px;
    @media (max-width:768px) {
        text-align: center;
        margin-bottom: 15px;
    }
}

.footer__signature {
    color: #fff;
}

.footer__signature svg {
    width: 100px;
    height: 30px;
    fill: #fff;
}
  
.footer__signature span,
.footer__signature svg {
    display: inline-block;
    vertical-align: middle
}

.footer__signature a {
    color: $moderna;
    text-decoration: none
}

.footer__back-to-top {
    text-align: center;
    cursor: pointer
}

@media (min-width:769px) {
    .footer__back-to-top {
        position: absolute;
        bottom: 20px;
        right: 20px;
        text-align: left
    }
}

.footer__back-to-top span {
    display: none
}

@media (min-width:769px) {
    .footer__back-to-top span {
        color: #fff;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        transition: .5s;
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
        opacity: 0
    }
}

@media (min-width:769px) {
    .footer__back-to-top:hover span {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }

    .footer__back-to-top:hover .footer__back-to-top-btn {
        background: #fff
    }

    .footer__back-to-top:hover .footer__back-to-top-btn:before {
        color: $moderna
    }
}

.footer__back-to-top-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    background: hsla(0, 0%, 100%, .2);
    transition: .5s
}

.footer__back-to-top-btn:before {
    content: "\E316";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: Material Icons;
    transition: .5s
}

.footer__cookie-preferences {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 80px;
    height: 80px;
    font-size: 0;
    line-height: 0;
    cursor: pointer
}

.social-media {
    width: 100%;
    max-width: 380px;
    margin: 0 auto
}

@media (min-width:769px) {
    .social-media {
        max-width: none
    }
}

@media (min-width:1025px) {
    .social-media {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.social-media__title {
    text-align: center;
    font-size: 28px;
    line-height: 33px;
    color: #fff;
    margin-bottom: 35px
}

@media (min-width:769px) {
    .social-media__title {
        font-size: 48px;
        line-height: 58px
    }
}

@media (min-width:1025px) {
    .social-media__title {
        text-align: left;
        margin-bottom: 0;
        font-size: 35px;
        line-height: 45px
    }
}

@media (min-width:1240px) {
    .social-media__title {
        font-size: 48px;
        line-height: 58px
    }
}

.social-media__list {
    margin: 0;
    padding: 0;
    list-style: none;
    *zoom: 1
}

.social-media__list:after,
.social-media__list:before {
    content: " ";
    display: table
}

.social-media__list:after {
    clear: both
}

@media (min-width:769px) {
    .social-media__list {
        text-align: center
    }
}

.social-media__item {
    display: inline-block;
    vertical-align: middle;
    padding: 15px 7px;
    float: left;
    width: 50%;
    text-align: left
}

@media (min-width:769px) {
    .social-media__item {
        padding: 15px 18px;
        width: auto;
        float: none
    }
}

@media (min-width:1025px) {
    .social-media__item {
        padding: 15px 20px
    }
}

@media (min-width:1240px) {
    .social-media__item {
        padding: 15px 25px
    }
}

.social-media__link {
    text-decoration: none;
    color: #fff;
    display: block
}

.social-media__link i {
    display: inline-block;
    vertical-align: middle;
    font-size: 25px
}

@media (min-width:769px) {
    .social-media__link i {
        font-size: 30px
    }
}

.social-media__link span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px
}

.menu--footer .menu__list {
    text-align: center;
    margin: 0;
    padding: 0;
    list-style: none
}

@media (min-width:769px) {
    .menu--footer .menu__list {
        text-align: left
    }
}

@media (min-width:769px) {
    .menu--footer .menu__list--level-0 {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
}

.menu--footer .menu__list--level-0 .menu__item {
    display: block;
}

.menu--footer .menu__list--level-0 .menu__item:last-child {
    border-bottom: none
}

.menu--footer .menu__list--level-0 .menu__item.show-in-header-menu {
    display: none
}

@media (min-width:769px) {
    .menu--footer .menu__list--level-0 .menu__item {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border-bottom: none
    }
}

.menu--footer .menu__list--level-0 .menu__link {
    font-size: 24px;
    line-height: 28px;
    color: $moderna;
    display: block;
    padding: 25px 0;
    cursor: pointer
}

@media (min-width:769px) {
    .menu--footer .menu__list--level-0 .menu__link {
        padding: 0;
        margin-bottom: 40px;
        cursor: default
    }
}

.menu--footer .menu__list--level-1 {
    display: none;
    margin: 15px 0 40px
}

@media (min-width:769px) {
    .menu--footer .menu__list--level-1 {
        display: block;
        margin: 0
    }
}

.menu--footer .menu__list--level-1 .menu__item {
    display: block;
    border-bottom: none
}

.menu--footer .menu__list--level-1 .menu__item:hover .menu__link:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.menu--footer .menu__list--level-1 .menu__link {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    margin-bottom: 0;
    cursor: pointer;
    padding: 8px 0;
    display: inline-block;
    position: relative
}

.menu--footer .menu__list--level-1 .menu__link:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: $moderna;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: .4s;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left
}

.menu--bottom .menu__list {
    text-align: center;
    margin: 0;
    padding: 0;
    list-style: none
}

@media (min-width:769px) {
    .menu--bottom .menu__list {
        text-align: left
    }
}

.menu--bottom .menu__list .menu__item {
    display: block
}

.menu--bottom .menu__list .menu__item:hover .menu__link:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.menu--bottom .menu__list .menu__link {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    margin-bottom: 0;
    cursor: pointer;
    padding: 5px 0;
    display: inline-block;
    position: relative
}

.menu--bottom .menu__list .menu__link:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: $moderna;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: .4s;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left
}

.fleet-list,
.fleet__info {
    padding: 40px 0 50px
}

@media (min-width:769px) {
    .fleet-list,
    .fleet__info {
        padding: 90px 0
    }
}

.fleet__pdf {
    max-width: 180px;
    margin: 0 auto
}

@media (min-width:769px) {
    .fleet__pdf {
        max-width: none
    }
}

.fleet__pdf a {
    text-decoration: none;
    display: block;
    line-height: 0
}

.fleet__pdf span {
    display: block;
    text-align: center;
    margin: 10px 0
}

.fleet__pdf svg {
    width: 100%;
    height: auto;
    transition: .5s
}

.fleet__pdf svg text {
    font-family: Averta-Black, sans-serif
}

.fleet__pdf svg:hover>g:first-child>path {
    fill: $moderna
}

.fleet__vessels .img-flag{
    display: inline-block;
    max-height: 40px;
    width: 40px;
    margin-left: 10px;
    @media (max-width:480px) {
         max-height: 35px;
        width: 35px;
    }
}

.fleet__subcategory {
    margin-bottom: 50px;
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    transition: .5s .3s
}

.fleet__subcategory.in-view {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}



@media (min-width:769px) {
    .fleet__subcategory {
        margin-bottom: 100px
    }
}

.banners {
    position: relative;
    width: 100%;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
    transition: .5s;
    overflow: hidden
}

.banners.in-view {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
}

@media (min-width:769px) {
    .banners {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
}

.banners__item {
    position: relative;
    height: 270px
}

@media (min-width:520px) {
    .banners__item {
        height: 410px
    }
}

@media (min-width:769px) {
    .banners__item {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 600px
    }
}

.banners__item:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .22);
    transition: .8s;
    z-index: 7
}

.banners__item.active:after {
    background: rgba(0, 0, 0, .5)
}

.banners__image {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 7
}

.banners__images-hover {
    display: none
}

@media (min-width:769px) {
    .banners__images-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 600px;
        z-index: 0
    }
}

.banners__info {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 0;
    left: 20px;
    z-index: 8
}

@media (min-width:1240px) {
    .banners__info {
        width: calc(100% - 80px);
        left: 40px
    }
}

.banners__title {
    font-size: 21px;
    line-height: 31px;
    color: #fff;
    padding: 20px 0;
    border-top: 1px solid hsla(0, 0%, 100%, .33)
}

@media (min-width:769px) {
    .banners__title {
        text-align: center
    }
}

@media (min-width:1240px) {
    .banners__title {
        text-align: left;
        font-size: 36px;
        line-height: 46px
    }
}

@media (min-width:769px) {
    .banners__title span {
        display: block;
        font-size: 30px;
        line-height: 40px
    }
}

@media (min-width:1240px) {
    .banners__title span {
        display: inline;
        font-size: 36px;
        line-height: 46px
    }
}

.banners__body {
    display: none
}

.banners__description {
    color: #fff;
    margin-bottom: 20px
}

.banners__url {
    display: block;
    margin-bottom: 20px
}

.banners__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8
}

.pagination {
    width: 100%;
    text-align: center
}

.pagination__list {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 20px 0;
     li{
         display: inline-block;
        vertical-align: middle;
        width: 60px;
        height: 60px;
        line-height: 60px;
        color: $moderna;
        transition: all ease-in-out 0.3s;
       
        @media (min-width:769px) {
              margin: 5px;
        }

         &.active {
            position: relative;
            color: #fff;
            background: 0 0;
            text-decoration: none;
            opacity: 1;
            transition: .5s;
            outline: none
        }
        a{
            display: block;
            transition: all ease-in-out 0.3s;
        }
        &.active,
        &:hover {
            color: #fff;
            background:  $moderna;
            a{
                color: #fff;
            }
        }

       &.active:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 2px;
            left: 2px;
            background: 0 0;
            z-index: -1
        }
       

      
    }
}

.pagination__item {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    line-height: 60px
}

@media (min-width:769px) {
    .pagination__item {
        margin: 5px
    }
}

.pagination__item .current,
.pagination__item a,
.pagination__item span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: $moderna;
    background: 0 0;
    text-decoration: none;
    opacity: 1;
    transition: .5s;
    outline: none
}

.pagination__item .next:after {
    content: "\E315";
    font-size: 20px;
    font-family: Material Icons
}

.pagination__item .prev:after {
    content: "\E314";
    font-size: 20px;
    font-family: Material Icons
}

.pagination__item .current,
.pagination__item a:hover {
    color: $moderna;
    background: rgba(2, 63, 125, .22)
}

.pagination__item .current:before,
.pagination__item a:hover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 2px;
    left: 2px;
    background: 0 0;
    z-index: -1
}

@media (min-width:769px) {
    .career {
        padding-top: 100px
    }
}

.career__container {
    padding: 40px 0
}

@media (min-width:769px) {
    .career__container {
        padding: 90px 0
    }
}

.career__info {
    @media (min-width:769px) {
         margin-bottom: 60px;
    }
    margin-bottom: 30px
}

.career__info-row {
    padding: 20px 0;
    border-bottom: 1px solid $grey;
    *zoom: 1
}

.career__info-row:last-child {
    border-bottom: none
}

.career__info-row:after,
.career__info-row:before {
    content: " ";
    display: table
}

.career__info-row:after {
    clear: both
}

.career__info-title {
    color: $moderna;
    width: 120px;
    float: left
}

.career__info-detail {
    width: calc(100% - 120px);
    float: left;
    padding-left: 15px
}

.career__ctas {
    margin-bottom: 50px
}

.career__skills ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.career__skills li {
    position: relative;
    color: $moderna;
    padding-left: 15px;
    margin-bottom: 25px
}

.career__skills li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $moderna
}

.career__notes {
    color: #777;
    font-size: 14px;
    margin-bottom: 60px
}

.career__notes:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #b7b7b7;
    margin: 50px 0
}

.values {
    position: relative;
    overflow: hidden;
    *zoom: 1;
    margin-bottom: 100px;
    @media (max-width:991px) {
        margin-bottom: 70px;
    }
     @media (max-width:767px) {
        margin-bottom: 50px;
    }
}

.values:after,
.values:before {
    content: " ";
    display: table
}

.values:after {
    clear: both
}

@media (min-width:769px) {
    .values__block {
        width: 50%;
        height: calc(100vh - 100px);
        min-height: 800px;
        float: left;
        opacity: 0;
        transition: .6s
    }

    .values__block .slick-list {
        height: 100%!important;
    }
}

.values__block--right {
    position: relative;
    background-color:$moderna;
    &:before{
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: -70px;
        z-index: 0;
        background: $moderna;
        -webkit-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        transform-origin: bottom left;
        -ms-transform: skew(-5deg,0deg);
        -webkit-transform: skew(-5deg,0deg);
        transform: skew(-5deg,0deg);
    }
}

@media (min-width:769px) {
    .values__block--right {
        -webkit-transform: translateX(100px);
        -ms-transform: translateX(100px);
        transform: translateX(100px)
    }
}

@media (min-width:769px) {
    .values__block--left {
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px)
    }
}

@media (min-width:769px) {
    .in-view .values__block {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

.values__image {
    height: 250px;
    position: relative;
    width: 100%;
    display: none
}

.values__image.active {
    display: block;
    z-index: 5;
}

.values__image:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .33)
}

@media (min-width:520px) {
    .values__image {
        height: 485px;
    }
}

@media (min-width:769px) {
    .values__image {
        position: absolute;
        display: block;
        height: calc(100vh - 100px);
        min-height: 800px;
        width: 50%;
        top: 0;
        left: 0
    }
}

.values__image h2 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    -webkit-transform: translate(50px, -50%);
    -ms-transform: translate(50px, -50%);
    transform: translate(50px, -50%);
    color: #fff;
    font-size: 48px;
    line-height: 58px;
    opacity: 0;
    transition: .7s;
    text-shadow: 0 2px 4px rgba(0, 0, 0, .33)
}

.in-view .values__image h2.animate {
    opacity: 1;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

@media (min-width:769px) {
    .values__image h2 {
        padding: 0 50px
    }
}

@media (min-width:1025px) {
    .values__image h2 {
        font-size: 60px;
        line-height: 70px;
        padding: 0 100px
    }
}

@media (min-width:1240px) {
    .values__image h2 {
        padding: 0 150px
    }
}

@media (min-width:1650px) {
    .values__image h2 {
        font-size: 90px;
        line-height: 100px
    }
}

.values__info {
    background: #fff
}

@media (min-width:769px) {
    .values__info {
        width: 50%;
        max-width: 810px;
        min-height: 65%;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 0;
        transition: .8s .5s
    }
}

@media (min-width:769px) {
    .in-view .values__info {
        opacity: 1;
        -webkit-transform: translate(-50px, -50%);
        -ms-transform: translate(-50px, -50%);
        transform: translate(-50px, -50%)
    }
}

@media (min-width:1025px) {
    .in-view .values__info {
        -webkit-transform: translate(-100px, -50%);
        -ms-transform: translate(-100px, -50%);
        transform: translate(-100px, -50%)
    }
}

@media (min-width:1240px) {
    .in-view .values__info {
        -webkit-transform: translate(-150px, -50%);
        -ms-transform: translate(-150px, -50%);
        transform: translate(-150px, -50%)
    }
}

.values__info-container {
    text-align: center;
    padding: 20px
}

@media (min-width:769px) {
    .values__info-container {
        text-align: left;
        padding: 0
    }
}

.values__info-container>.values__title {
    margin-bottom: 0px
}

@media (min-width:769px) {
    .values__info-container>.values__title {
        display: none;
        margin-bottom: 0
    }
}

@media (min-width:769px) {
    .values__info-inner {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

.values__title-container {
    display: none
}

@media (min-width:769px) {
    .values__title-container {
        display: block;
        text-align: center;
        padding: 50px 10px
    }
}

@media (min-width:1025px) {
    .values__title-container {
        padding: 60px 20px
    }
}

.values__title {
    display: inline-block;
    vertical-align: middle;
    padding: 5px 25px;
    font-size: 28px;
    line-height: 34px;
    color: gray;
    transition: .4s;
    position:relative;
    padding-top:20px;
    z-index: 2;
}

@media (min-width:769px) {
    .values__title {
        padding: 5px 9px;
        font-size: 16px;
        line-height: 22px;
         padding-top:20px;
    }
}

@media (min-width:1025px) {
    .values__title {
        font-size: 22px;
        line-height: 28px
    }
}

@media (min-width:1240px) {
    .values__title {
        padding: 5px 15px;
        font-size: 25px;
        line-height: 31px;
         padding-top:20px;
    }
}

@media (min-width:1540px) {
    .values__title {
        padding: 5px 25px;
        font-size: 28px;
        line-height: 34px;
         padding-top:20px;
    }
}

.values__title--blue {
    color: $moderna
}

.values__title--cyan {
    color: $moderna
}

.values__title--light-blue {
    color: $moderna
}

.values__title--orange {
    color: #58575c
}

.values__title--green {
    color: #143f3c
}

@media (min-width:769px) {
    .values__title {
        cursor: pointer;
        color: gray
    }
}

.values__title.active.values__title--blue,
.values__title:hover.values__title--blue {
    color: $moderna
}

.values__title.active.values__title--cyan,
.values__title:hover.values__title--cyan {
    color: $moderna
}

.values__title.active.values__title--light-blue,
.values__title:hover.values__title--light-blue {
    color: $moderna
}

.values__title.active.values__title--orange,
.values__title:hover.values__title--orange {
    color: #58575c
}

.values__title.active.values__title--green,
.values__title:hover.values__title--green {
    color: #143f3c
}

.values__title.active {
    cursor: default
}

.values__title.active:before{
    position:absolute;
    width: 0; 
    content: "";
    height: 0; 
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid $moderna;
    transition: all ease-in-out 0.3s;
}
.values__description {
    display: block
}

@media (min-width:769px) {
    .values__description {
        display: none
    }

    .values__description.active {
        display: block
    }
}

.values__description-inner {
    color: gray;
    font-size: 20px;
    line-height: 28px;
    text-align: center

}

@media (min-width:769px) {
    .in-view .values__description-inner.animate {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

@media (min-width:769px) {
    .values__description-inner {
        -webkit-transform: translateY(30px);
        -ms-transform: translateY(30px);
        transform: translateY(30px);
        opacity: 0;
        transition: .5s;
        font-size: 24px;
        line-height: 40px;
        padding: 20px 50px;
        max-width: 80%;
        width: 100%;
        display: block;
        margin: 0 auto;
    }
}

@media (min-width:1540px) and (min-height:900px) {
    .values__description-inner {
        font-size: 32px;
        line-height: 48px;
        padding: 20px 80px
    }
}

.values__description--blue .values__description-inner {
    color: $moderna
}

.values__description--cyan .values__description-inner {
    color: $moderna
}

.values__description--light-blue .values__description-inner {
    color: $moderna
}

.values__description--orange .values__description-inner {
    color: #58575c
}

.values__description--green .values__description-inner {
    color: #143f3c
}

.values__dots {
    padding: 5px 0 5px;
    text-align: center
}

@media (min-width:769px) {
    .values__dots {
        display: none
    }
}

.values__dots .slick-dots li button {
    background: #bfc0c1
}

.values__dots .slick-dots li.slick-active button,
.values__dots .slick-dots li:hover button {
    background: #58575c
}

.code-art {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1
}

.table__headings {
    display: none
}

@media (min-width:769px) {
    .table__headings {
        padding: 10px;
        border-bottom: 2px solid #dedede;
        width: 100%;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
}

.table__heading {
    width: 15%;
    font-size: 12px;
    color: $moderna
}

.table__heading:first-child {
    width: 40%
}

.table__row {
    .table__row--up{
        position:relative;
        .table__cell{
            transition: all 0.4s;
        }
        &:before{
            position:absolute;
            left: 0;
            top: 0;
            content: "";
            background: $moderna!important;
            height: 0;
            width: 100%;
            transition: all 0.4s;
        }

    }
    &.active{
        .table__row--up{
            .table__cell{
                color:#fff;
            }
              &:before{
                height: 100%;
                z-index: -10;
              }
        }
    }

}


.table__headings-extended .table__heading:first-child {
    width: 25%
}

.table__row {
    border-bottom: 2px solid #dedede;
    transition: .4s
}

@media (min-width:769px) {
    .table__row {
        border-bottom: 1px solid #dedede
    }
}

.table__row:last-child {
    border-bottom: none
}

.table__row--up {
    cursor: pointer;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 10px;
    position:relative;
    @media (min-width:769px) {
        &:after{
            content: "\f107";
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            position:absolute;
            right: 5px;
            top: 50%;
            color: $moderna;
            transform:translateY(-50%);
            transition: .4s;
            -webkit-font-smoothing: antialiased;
        }
    }
    
}

.table__row.active .table__row--up {
     &:after{
        transform:translateY(-50%) rotate(-180deg);
        color: #fff;
    }
}
@media (min-width:769px) {
    .table__row--up {
        padding: 25px 10px
    }
}

@media (min-width:769px) {
  
    .table__row--up:hover .table__cell {
        color: $moderna
    }
}

.table__row--down {
    display: none;
    *zoom: 1
}

.table__row--down:after,
.table__row--down:before {
    content: " ";
    display: table
}

.table__row--down:after {
    clear: both
}

@media (min-width:769px) {
    .table__row.active {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .22);
        border-bottom: none
    }
}

.table__row.active .table__row--up {
}

.table__cell {
    width: 15%;
    transition: .4s;
    color: $light_grey;
    display: none
}

.table__cell:first-child {
    position: relative;
    display: block;
    width: 100%;
    color: $moderna
}

.table__cell:first-child:after {
    content: "\E313";
    font-family: Material Icons;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #4D4D4F
}

.active .table__cell:first-child:after {
    content: "\E316";
    color: $moderna
}

@media (min-width:769px) {
    .table__cell:first-child {
        width: 40%;
        font-size: 18px
    }

    .table__row-extended .table__cell:first-child {
        width: 25%
    }

    .table__cell:first-child:after {
        display: none
    }
}

@media (min-width:769px) {
    .table__cell {
        display: block
    }
}

.table__image {
    width: 100%
}

@media (min-width:769px) {
    .table__image {
        width: 50%;
        float: left
    }

    .table__row-extended .table__image {
        width: 50%
    }
}

.table__image img {
    width: 100%;
    max-width: 100%;
    height: auto
}

@media (min-width:769px) {
    .table__image img {
        width: 100%;
        max-width: 90%;
        padding: 15px 10px
    }
}

.table__info-wrapper {
    width: 100%;
    padding: 15px 0
}

@media (min-width:769px) {
    .table__info-wrapper {
        float: left;
        width: auto;
        padding: 15px 10px 15px 0
    }
}

@media (min-width:769px) {
    .table__info--main {
        display: none
    }
}

.table__info--additional .table__info-row:last-child {
    border-bottom: none
}

.table__info-row {
    color: #333;
    border-bottom: 1px solid #dedede;
    padding: 5px 10px;
    display: block;
    *zoom: 1
}

.table__info-row:after,
.table__info-row:before {
    content: " ";
    display: table
}

.table__info-row:after {
    clear: both
}

@media (min-width:769px) {
    .table__info-row {
        border-bottom: none;
        padding: 0;
        margin-bottom: 5px
    }
}

.table__info-row-label {
    font-size: 13px;
    color: $moderna;
    text-align: left;
    float: left;
    width: 150px;
    font-family: Averta-Black;
}

@media (min-width:769px) {
    .table__info-row-label {
        display: inline-block;
        font-size: 16px;
        color: #8c8c8c;
        float: none;
        width: auto;
        min-width: 150px
    }
}

.table__info-row-value {
    float: left;
    width: calc(100% - 150px)
}

@media (min-width:769px) {
    .table__info-row-value {
        display: inline-block;
        float: none;
        width: auto
    }
}

.single-sustainabilities {
    padding: 40px 0
}

@media (min-width:769px) {
    .single-sustainabilities {
        padding: 95px 0
    }
}

.single-sustainabilities .text {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dedede
}

@media (min-width:769px) {
    .single-sustainabilities .text {
        margin-bottom: 60px;
        padding-bottom: 60px
    }
}

.single-sustainabilities__chart {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dedede;
    overflow-x: hidden
}

@media (min-width:769px) {
    .single-sustainabilities__chart {
        margin-bottom: 60px;
        padding-bottom: 60px
    }
}

.single-sustainabilities__title {
    font-family: Averta-Black, sans-serif;
    font-size: 24px;
    color: $moderna;
    margin-bottom: 30px
}

.single-sustainabilities .container-small:last-child .single-sustainability__chart,
.single-sustainabilities .container-small:last-child .text {
    padding-bottom: 0;
    border-bottom: none
}

.error-page {
    position: relative;
    min-height: calc(100vh - 75px);
    background: #2e77b1;
    background: linear-gradient(180deg, #2e77b1 0, $moderna);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e77b1", endColorstr="$moderna", GradientType=0);
    overflow: hidden;
    padding-top: 75px
}

@media (min-width:769px) {
    .error-page {
        min-height: 100vh;
        padding-top: 0px
    }
}

.error-page__container {
    display: table;
    width: 100%;
    min-height: calc(100vh - 75px);
    position: relative;
    .info-block__info-title{
        padding: 15px;
    }
    
}

@media (min-width:769px) {
    .error-page__container {
        min-height: calc(100vh - 100px);
         .info-block__holder .skew-button{
            float: left;
         }
    }
}

.error-page__inner {
    padding: 0px;
    display: table-cell;
    vertical-align: middle
}

.error-page .particles-waves {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none
}

.error-page__label {
    font-family: Averta-Black, sans-serif;
    color: gray!important;
    margin-bottom: 35px;
    font-size: 21px;
    line-height: 28px;
    background-color: #ffffff!important;
}

@media (min-width:769px) {
    .error-page__label {
        font-size: 28px;
        line-height: 36px
    }
}

.error-page__title {
    color: #fff;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 35px
}

@media (min-width:769px) {
    .error-page__title {
        font-size: 60px;
        line-height: 72px
    }
}

.error-page__content {
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 30px
}

@media (min-width:769px) {
    .error-page__content {
        font-size: 18px;
        line-height: 30px
    }
}

.error-page__links a {
    display: block;
    margin-bottom: 25px;
    font-size: 18px
}

.menu--secondary {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .11)
}

@media (min-width:769px) {
    .menu--secondary {
        -webkit-transform: translateY(100vh);
        -ms-transform: translateY(100vh);
        transform: translateY(100vh);
        position: relative;
        opacity: 0;
        transition: opacity 0s 0s
    }

    .no-hero .menu--secondary,
    .scrolled .menu--secondary {
        z-index: 2;
        -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 1;
        transition: opacity .4s .8s
    }
}

@media (min-width:1025px) {
    .menu--secondary {
        position: fixed;
        top: 0;
        left: 180px;
        width: calc(100% - 180px);
        height: 100px;
        padding: 0;
        text-align: center;
        box-shadow: none
    }

    .menu--secondary,
    .no-hero .menu--secondary,
    .scrolled .menu--secondary {
        -webkit-transform: translate3d(50px, 0, 0);
        transform: translate3d(50px, 0, 0);
        opacity: 0;
        visibility: hidden;
        transition: all .5s;
        z-index: 10
    }

    .menu-secondary--visible .menu--secondary {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        transition-delay: .5s;
        opacity: 1;
        visibility: visible
    }

    .fixed-banner--visible .menu--secondary {
        -webkit-transform: translateY(112px) translateY(-100%);
        -ms-transform: translateY(112px) translateY(-100%);
        transform: translateY(112px) translateY(-100%)
    }

    .fixed-banner--visible.menu-secondary--visible .menu--secondary {
        -webkit-transform: translateY(112px);
        -ms-transform: translateY(112px);
        transform: translateY(112px)
    }
}

.menu--secondary .menu__list {
    margin: 0;
    padding: 0;
    list-style: none
}

@media (min-width:1025px) {
    .menu--secondary .menu__list {
        display: table;
        height: 100%;
        text-align: center;
        margin: 0 auto
    }
}

.menu--secondary .menu__item {
    padding: 20px 15px;
    position: relative
}

.menu--secondary .menu__item:before {
    content: "";
    position: absolute;
    width: calc(100% - 30px);
    height: 3px;
    bottom: 0;
    left: 15px;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: .5s;
    background: $moderna;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left
}

@media (min-width:1025px) {
    .menu--secondary .menu__item {
        position: relative;
        padding: 5px 15px;
        display: table-cell;
        vertical-align: middle
    }

    .menu--secondary .menu__item:before {
        display: none
    }
}

.menu--secondary .menu__item--current>.menu__link,
.menu--secondary .menu__item:hover>.menu__link {
    color: $moderna
}

.menu--secondary .menu__item--current:before,
.menu--secondary .menu__item:hover:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.menu--secondary .menu__link {
    text-decoration: none;
    color: rgba(2, 63, 125, .5);
    transition: .4s
}

.loader__wrapper {
    text-align: center;
    padding: 50px 0
}

.loader__wrapper span {
    display: block;
    margin-top: 30px;
    color: $moderna
}

.simple-text {
    overflow: hidden
}

.simple-text__container {
    padding: 40px 0
}
.container-full  .simple-text__container {
    padding:  0;
}
.container-full  .simple-text__container .section__content>*{
     padding: 0px 0 40px;
}
@media (min-width:769px) {
    .simple-text__container {
        padding: 95px 0
    }
    .container-full  .simple-text__container {
        padding: 0;
    }
    .container-full  .simple-text__container .section__content>*{
         padding: 95px 0;
    }
}

@media (min-width:1240px) {
    .simple-text__container {
        padding: 95px 0
    }
     .container-full  .simple-text__container {
        padding: 0;
    }
    .container-full  .simple-text__container .section__content>*{
         padding: 95px 0;
    }
}

@media (min-width:769px) {

    .simple-text__container .text>* {
        padding-left: 30px;
        padding-right: 30px
    }
    .container-full  .simple-text__container .text>* {
        padding-left: 0px;
        padding-right: 0px
    }
}

@media (min-width:1240px) {
    .simple-text__container .text>* {
        padding-left: 60px;
        padding-right: 60px
    }
    .container-full  .simple-text__container .text>* {
        padding-left: 0px;
        padding-right: 0px
    }
}

.container-full  .simple-text__container .text{
    margin-bottom: 0px; 
}

.simple-text img {
    width: calc(100% + 40px);
    margin-left: -20px;
    height: auto;
    margin-bottom: 30px;
    margin-top: 30px;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    transition: .4s;
    background: #eee
}
.story.simple-text img.img-responsive{
    max-width: initial;
}

.simple-text img.in-view {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

@media (min-width:769px) {
    .simple-text img {
        width: 100vw;
        margin-left: -50px
    }
}

@media (min-width:1240px) {
    .simple-text img {
        width: 1200px !important;
        margin-left: -90px
    }
}

.simple-text figure {
    width: auto !important
}

.simple-text figcaption {
    margin-bottom: 60px
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all .8s ease-in-out .8s;
    background: #fff
}

.preloader.pre-hide,
.preloader.pre-hide:before {
    z-index: -1;
    opacity: 0;
    transition: all .8s ease-in-out .8s
}

.preloader.loaded:before {
    -webkit-transform: translate(0) scaleY(0);
    -ms-transform: translate(0) scaleY(0);
    transform: translate(0) scaleY(0)
}

.preloader:before {
    content: "";
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    background-color: hsla(0, 0%, 100%, .1);
    display: block;
    position: absolute;
    -webkit-transform: translate(0) scaleY(1);
    -ms-transform: translate(0) scaleY(1);
    transform: translate(0) scaleY(1);
    transition: .8s .4s;
    -webkit-transform-origin: bottom center;
    -ms-transform-origin: bottom center;
    transform-origin: bottom center;
    opacity: 1
}

.preloader__logo {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: .8s 0s;
    z-index: 10;
    opacity: 1;

}

.preloader__logo svg {
    width: 100%;
    height: auto;
    max-width: 230px;
    display: block;
    margin: 0 auto;
}

.hide .preloader__logo {
    -webkit-transform: translate(-50%, -150%);
    -ms-transform: translate(-50%, -150%);
    transform: translate(-50%, -150%);
    opacity: 0;
    transition: opacity 1s ease 0s, -webkit-transform 1s ease 0s;
    transition: transform 1s ease 0s, opacity 1s ease 0s;
    transition: transform 1s ease 0s, opacity 1s ease 0s, -webkit-transform 1s ease 0s
}

.loaded .preloader__logo {
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    opacity: 0
}

.preloader__count {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-in;
    z-index: 10
}

.preloader__count.show {
    opacity: 1;
    visibility: visible;
    display: none;
}

.preloader__count.pre-hide {
    opacity: 0;
    visibility: hidden
}

.preloader__counter {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;
    line-height: 20px;
    overflow: hidden;
    width: 100px;
    font-size: 21px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    margin-top: 50px;
    padding-top: 5px;
    display: none;
}

@media (min-width:769px) {
    .preloader__counter {
        margin-top: 90px
    }
}

.preloader__counter-progress {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5px;
    position: relative
}

.preloader__counter-progress:after,
.preloader__counter-progress:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 12px;
    top: -12px;
    z-index: 2;
    background-color: $moderna
}

.preloader__counter-progress:after {
    top: auto;
    bottom: -6px
}

.preloader__counter-progress .value {
    color: #fff
}

.preloader__counter-progress .hundredth {
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.preloader__counter-progress .tenth,
.preloader__counter-progress .unit {
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

.preloader__counter-progress .unit {
    -webkit-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    transform: translateY(-200px)
}

.show .preloader__counter-progress .hundredth {
    -webkit-animation-name: b;
    animation-name: b
}

.show .preloader__counter-progress .tenth {
    -webkit-animation-name: c;
    animation-name: c
}

.show .preloader__counter-progress .unit {
    -webkit-animation-name: a;
    animation-name: a
}

.preloader__counter-total {
    color: #b6b6b6
}

@-webkit-keyframes a {
    0% {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes a {
    0% {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes b {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
}

@keyframes b {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
}

@-webkit-keyframes c {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px)
    }
}

@keyframes c {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    to {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px)
    }
}

.services {
    width: 100%;
    position: relative;
    background: #58575c
}

.services__container {
    padding: 45px 0 150px
}

@media (min-width:769px) {
    .services__container {
        padding: 100px 0 200px
    }
}

.section__content .services h1,
.section__content .services h2,
.section__content .services h5,
.section__content .services h6,
.section__content h3 .services h4,
.services .promises__title,
.services .section__content h1,
.services .section__content h2,
.services .section__content h3 h4,
.services .section__content h5,
.services .section__content h6,
.services .section__subtitle {
    margin-bottom: 70px;
    width: 100%;
    max-width: 230px
}

@media (min-width:520px) {

    .section__content .services h1,
    .section__content .services h2,
    .section__content .services h5,
    .section__content .services h6,
    .section__content h3 .services h4,
    .services .promises__title,
    .services .section__content h1,
    .services .section__content h2,
    .services .section__content h3 h4,
    .services .section__content h5,
    .services .section__content h6,
    .services .section__subtitle {
        max-width: none
    }
}

@media (min-width:769px) {

    .section__content .services h1,
    .section__content .services h2,
    .section__content .services h5,
    .section__content .services h6,
    .section__content h3 .services h4,
    .services .promises__title,
    .services .section__content h1,
    .services .section__content h2,
    .services .section__content h3 h4,
    .services .section__content h5,
    .services .section__content h6,
    .services .section__subtitle {
        padding-right: 30px;
        margin-bottom: 30px;
        max-width: 310px
    }
}

.services__item {
    margin-bottom: 60px
}

@media (min-width:769px) {
    .services__item {
        -webkit-transform: translateY(50px);
        -ms-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
        transition-duration: .5s
    }

    .services__item:first-child {
        transition-delay: .3s
    }

    .services__item:nth-child(2) {
        transition-delay: .4s
    }

    .services__item:nth-child(3) {
        transition-delay: .5s
    }

    .services__item:nth-child(4) {
        transition-delay: .6s
    }

    .services__item:nth-child(5) {
        transition-delay: .7s
    }

    .services__item:nth-child(6) {
        transition-delay: .8s
    }

    .services__item:nth-child(7) {
        transition-delay: .9s
    }

    .services__item:nth-child(8) {
        transition-delay: 1s
    }

    .services__item:nth-child(9) {
        transition-delay: 1.1s
    }

    .services__item:nth-child(10) {
        transition-delay: 1.2s
    }

    .services__item:nth-child(11) {
        transition-delay: 1.3s
    }

    .services__item:nth-child(12) {
        transition-delay: 1.4s
    }

    .in-view .services__item {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
}

.services__holder {
    position: relative
}

.services__holder a {
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.services__holder i {
    display: block;
    font-size: 60px;
    color: #fff;
    margin-bottom: 10px
}

.services__holder .icon-commercial {
    font-size: 40px;
    min-height: 60px
}

.services__holder h3 {
    color: $moderna;
    font-size: 24px;
    line-height: 36px
}

@media (min-width:769px) {
    .services__holder h3 {
        font-size: 36px;
        line-height: 48px;
        letter-spacing: -1px
    }
}

.services__inner {
    text-align: center
}

@media (min-width:769px) {
    .services__inner {
        text-align: left
    }
}

.services__inner p {
    color: $moderna
}

.careers {
    position: relative;
    width: 100%;
    background: #0d2137
}

.careers__container {
    padding: 50px 0 100px
}

.careers__banner {
    height: 485px;
    margin-bottom: 20px
}

.careers__banner,
.careers__banner .moz-background-picture {
    position: relative
}

.careers__banner .moz-background-picture:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, .11) 33%, rgba(0, 0, 0, .11) 34%, rgba(0, 0, 0, .38) 67%, rgba(0, 0, 0, .88));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c000000", endColorstr="#e0000000", GradientType=0);
    transition: .4s
}

@media (min-width:769px) {
    .careers__banner {
        height: 555px;
        margin-bottom: 0
    }

    .careers__banner .moz-background-picture:after {
        background: linear-gradient(180deg, rgba(0, 0, 0, .11) 33%, rgba(0, 0, 0, .28) 67%, rgba(0, 0, 0, .66));
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c000000", endColorstr="#a8000000", GradientType=0)
    }
}

@media (min-width:769px) {
    .careers__banner:hover .moz-background-picture:after {
        background: linear-gradient(180deg, rgba(0, 0, 0, .11) 33%, rgba(0, 0, 0, .11) 34%, rgba(0, 0, 0, .38) 67%, rgba(0, 0, 0, .88));
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c000000", endColorstr="#e0000000", GradientType=0)
    }
}

.careers__info {
    position: absolute;
    width: calc(100% - 60px);
    bottom: 0;
    left: 30px;
    border-top: 1px solid hsla(0, 0%, 100%, .33)
}

@media (min-width:769px) {
    .careers__info {
        width: calc(100% - 80px);
        left: 40px
    }
}

.careers__body {
    display: block
}

@media (min-width:769px) {
    .careers__body {
        display: none
    }

    .touch-device .careers__body {
        display: block
    }
}

.careers__title {
    color: #fff;
    font-size: 28px;
    line-height: 34px;
    margin: 30px 0
}

.careers__subtitle {
    color: #fff;
    margin-bottom: 30px
}

.careers__cta {
    margin-bottom: 30px;
    display: block
}

.careers__link {
    height: 100%;
    z-index: 1
}

#waves,
.careers__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

#waves {
    display: block;
    z-index: 100;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%)
}

.entry__container {
    padding: 50px 0 0
}

@media (min-width:769px) {
    .entry__container {
        padding: 100px 0
    }
}

@media (min-width:769px) {
    .entry .text {
        -webkit-column-count: 2;
        column-count: 2
    }
}

.entry__link {
    margin-bottom: 30px
}

.glance__container {
    padding: 50px 20px 30px;
    *zoom: 1
}

.glance__container:after,
.glance__container:before {
    content: " ";
    display: table
}

.glance__container:after {
    clear: both
}

@media (min-width:769px) {
    .glance__container {
        padding: 80px 0
    }
}

@media (min-width:1240px) {
    .glance__container {
        padding: 80px 0
    }
}

.glance__wrapper {
    padding: 0;
    .section__title{
        @media (max-width:768px) {
            text-align: center;
        }
    }
}

@media (min-width:769px) {
    .glance__wrapper {
        padding: 0 0px
    }
}

.glance__subtitle {
    width: 100%;
    margin-left: 0
}

@media (min-width:769px) {
    .glance__subtitle {
        width: 33.33333%;
        margin-left: 0;
        float: left;
        padding-left: 20px;
        padding-right: 30px
    }
}

@media (min-width:1240px) {
    .glance__subtitle {
       
        margin-left: calc((100% - 1200px)/2);
        padding-left: 0
    }
}

.contact {
    padding: 30px 0;
    @media (min-width:769px) {
         padding: 70px 0 30px;
    }
}

.contact__office {
    margin-bottom: 25px;
   display: block
}


@media (min-width:769px) {
    .contact__office {
        display: block;
        border-bottom: 1px solid #dedede
    }

    .contact__office:last-child {
        border-bottom: none
    }
}

.contact__address {
    margin-bottom: 25px
}

.contact__address a {
    text-decoration: none;
    color: #333;
    transition: .4s
}

.contact__address a:hover {
    color: $moderna
}

.contact__address p:first-child {
    margin-top: 0
}

.contact__address p:last-child {
    margin-bottom: 0
}
.contact__list abbr,
.contact__address abbr {
    color: $moderna
}

.contact__label {
    display: block;
    color: $moderna;
    margin-bottom: 5px;
    text-align:center;
}

@media (min-width:769px) {
    .contact__label {
        display: none
    }
}

.contact__name {
    display: none;
    position: relative;
    color: $grey;
    padding-bottom: 25px;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    font-family: Averta-Black,sans-serif;
    display: block;
    @media (max-width:768px) {
        font-size: 21px;
        line-height: 25px;
    }

}


.contact__list {
    display: block
}


.contact__list-item {
    padding: 20px 0;
    border-bottom: 1px solid #c9c9c9;
    text-align: center;

}
.contact__details{
    display: table;
    margin:0 auto;
    text-align: center;
    font-size:20px;
    @media (max-width:768px) {
         font-size:18px;
    }

}
.contact__list-item:last-child {
    border-bottom: none
}

@media (min-width:769px) {
    .contact__list-item {
        padding: 0;
        margin-bottom: 30px;
        border-bottom: none
    }
}

.contact__list-item .promises__title,
.contact__list-item .section__content h1,
.contact__list-item .section__content h2,
.contact__list-item .section__content h3 h4,
.contact__list-item .section__content h5,
.contact__list-item .section__content h6,
.contact__list-item .section__subtitle,
.section__content .contact__list-item h1,
.section__content .contact__list-item h2,
.section__content .contact__list-item h5,
.section__content .contact__list-item h6,
.section__content h3 .contact__list-item h4 {
    margin-bottom: 0
}

@media (min-width:769px) {

    .contact__list-item .promises__title,
    .contact__list-item .section__content h1,
    .contact__list-item .section__content h2,
    .contact__list-item .section__content h3 h4,
    .contact__list-item .section__content h5,
    .contact__list-item .section__content h6,
    .contact__list-item .section__subtitle,
    .section__content .contact__list-item h1,
    .section__content .contact__list-item h2,
    .section__content .contact__list-item h5,
    .section__content .contact__list-item h6,
    .section__content h3 .contact__list-item h4 {
        margin-bottom: 10px
    }
}

.contact__list-item a {
    text-decoration: none;
    color: #333;
    transition: .4s;
    
}
.elipsis{
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width: 100%;
}

.contact__list-item a:hover {
    color: $moderna
}

.contact__stick-menu {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .11);
    display: block
}

@media (min-width:769px) {
    .contact__stick-menu {
        display: none
    }
}

.contact__stick-menu-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.contact__stick-menu-item {
    padding: 20px 15px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer
}

.contact__stick-menu-item span {
    color: rgba(2, 63, 125, .5);
    transition: .4s
}

.contact__stick-menu-item:before {
    content: "";
    position: absolute;
    width: calc(100% - 30px);
    height: 3px;
    bottom: 0;
    left: 15px;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: .5s;
    background: $moderna;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left
}

.contact__stick-menu-item:hover span,
.slick-current .contact__stick-menu-item span {
    color: $moderna
}

.contact__stick-menu-item:hover:before,
.slick-current .contact__stick-menu-item:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

#map,
.map {
    height: 530px
}

@media (min-width:769px) {
    .terms-conditions {
        padding-top: 100px
    }
}

.terms-conditions__container {
    padding: 40px 0
}

@media (min-width:769px) {
    .terms-conditions__container {
        padding: 95px 0
    }
}

.terms-conditions__item {
    margin-bottom: 50px;
    border-bottom: 1px solid #dedede
}

.terms-conditions__item:last-child {
    border-bottom: none;
    margin-bottom: 0
}



.sustainability__container {
    padding: 40px 0 0
}

@media (min-width:769px) {
    .sustainability__container {
        padding: 95px 0 0
    }
}

.sustainability__block {
    overflow: hidden
}

.sustainability__block:nth-child(2n) .sustainability__image {
    -webkit-transform: translateX(80px);
    -ms-transform: translateX(80px);
    transform: translateX(80px)
}

.sustainability__block:nth-child(2n) .sustainability__info {
    -webkit-transform: translateX(-80px);
    -ms-transform: translateX(-80px);
    transform: translateX(-80px)
}

.sustainability__block:nth-child(2n).in-view .sustainability__image,
.sustainability__block:nth-child(2n).in-view .sustainability__info {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

.sustainability__image {
    position: relative;
    width: 100%;
    height: 220px
}

@media (min-width:769px) {
    .sustainability__image {
        height: 660px;
        opacity: 0;
        -webkit-transform: translateX(-80px);
        -ms-transform: translateX(-80px);
        transform: translateX(-80px);
        transition: .5s
    }

    .in-view .sustainability__image {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

.sustainability__url {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1
}

@media (min-width:1025px) {
    .sustainability__wrapper {
        margin: 0 30px
    }
}

.sustainability__info {
    padding: 30px 20px 50px
}

@media (min-width:769px) {
    .sustainability__info {
        opacity: 0;
        -webkit-transform: translateX(80px);
        -ms-transform: translateX(80px);
        transform: translateX(80px);
        transition: .5s;
        padding: 0 20px;
        height: 580px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto
    }

    .in-view .sustainability__info {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

@media (min-width:1025px) {
    .sustainability__info {
        padding: 0;
        width: 85%;
        margin: 0 auto
    }
}

.sustainability__title {
    font-family: Averta-Black, sans-serif;
    font-size: 24px;
    color: $moderna;
    margin-bottom: 20px
}

.sustainability__content {
    margin-bottom: 30px
}

.sustainability__subtitle {
    font-size: 28px;
    line-height: 34px;
    color: $moderna;
    margin-bottom: 30px
}

@media (min-width:1025px) {
    .sustainability__subtitle {
        font-size: 36px;
        line-height: 48px
    }
}

.sustainability-logos {
    width: 100%;
    position: relative
}

.sustainability-logos__container {
    padding: 65px 0
}

.sustainability-logos__logo {
    width: 110px;
    height: 110px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    margin: 10px auto
}

.sustainability-logos__logo img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 105px;
    height: auto
}

@media (min-width:769px) {
    .history {
        padding-top: 80px
    }
}

.history__block,
.history__image {
    position: relative
}

.history__image {
    height: 215px
}

@media (min-width:769px) {
    .history__image {
        overflow: hidden;
        height: calc(100vh - 100px - 68px)
    }
}

@media (min-width:1025px) {
    .history__image {
        height: calc(100vh - 100px)
    }
}

.history__image .moz-background-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 1s cubic-bezier(.455, .03, .515, .955)
}

.history__image:before {
    content: "";
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, .33)
}

.history__date,
.history__image:before {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1
}

.history__date {
    top: 50%;
    text-align: center;
    color: #fff;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 48px;
    line-height: 58px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-shadow: 0 2px 4px rgba(0, 0, 0, .33)
}

@media (min-width:769px) {
    .history__date {
        opacity: 0;
        transition: all .8s;
        transition-delay: 1.5s;
        font-size: 99px;
        line-height: 109px;
        padding: 0 50px
    }

    .history__date.seq-right {
        -webkit-transform: translateX(-30px) translateY(-50%) translateZ(0);
        transform: translateX(-30px) translateY(-50%) translateZ(0)
    }

    .history__date.seq-left {
        -webkit-transform: translateX(30px) translateY(-50%) translateZ(0);
        transform: translateX(30px) translateY(-50%) translateZ(0)
    }
}

@media (min-width:1240px) {
    .history__date {
        padding: 0 100px
    }
}

.history__content {
    width: 100%
}

@media (min-width:769px) {
    .history__content {
        display: table;
        height: calc(100vh - 100px - 68px)
    }
}

@media (min-width:1025px) {
    .history__content {
        height: calc(100vh - 100px)
    }
}

.history__cell {
    display: table-cell;
    vertical-align: middle;
    *zoom: 1
}

.history__cell:after,
.history__cell:before {
    content: " ";
    display: table
}

.history__cell:after {
    clear: both
}

.history__inner {
    padding: 20px
}

@media (min-width:769px) {
    .history__inner {
        display: table;
        background: #fff;
        padding: 20px 50px;
        max-width: 800px;
        height: 60vh;
        transition: all .8s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        transition-delay: 1s
    }
}

@media (min-width:1240px) {
    .history__inner {
        padding: 20px 100px
    }
}

@media (min-width:769px) {
    .history__holder {
        opacity: 0;
        display: table-cell;
        vertical-align: middle;
        transition: all .8s cubic-bezier(.645, .045, .355, 1);
        transition-delay: 1s
    }

    .history__holder .section__content {
        margin-bottom: 0
    }
}

.seq-init-touch #sequence {
    -ms-touch-action: auto !important;
    touch-action: auto !important
}

@media (min-width:769px) {
    .seq {
        position: relative;
        height: calc(100vh - 100px - 68px);
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        margin: 0 auto;
        padding: 0;
        -webkit-text-size-adjust: 100%
    }
}

@media (min-width:1025px) {
    .seq {
        height: calc(100vh - 100px)
    }
}

@media (min-width:769px) {

    .seq .seq-canvas,
    .seq .seq-canvas>*,
    .seq .seq-screen {
        position: absolute;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden
    }
}

@media (min-width:769px) {
    .seq .seq-canvas>* {
        display: inline-block;
        vertical-align: top;
        width: 100%
    }
}

@media (min-width:769px) {
    .seq .seq-preload-circle {
        fill: $moderna
    }
}

@media (min-width:769px) {
    .seq .seq-canvas {
        white-space: normal
    }
}

@media (min-width:769px) {

    .seq .seq-canvas .seq-bottom,
    .seq .seq-canvas .seq-top {
        transition: -webkit-transform 1s cubic-bezier(.455, .03, .515, .955);
        transition: transform 1s cubic-bezier(.455, .03, .515, .955);
        transition: transform 1s cubic-bezier(.455, .03, .515, .955), -webkit-transform 1s cubic-bezier(.455, .03, .515, .955)
    }
}

@media (min-width:769px) {
    .seq .seq-canvas .seq-right {
        float: right
    }
}

@media (min-width:769px) {
    .seq .seq-canvas .seq-left {
        float: left
    }
}

@media (min-width:769px) {

    .seq .seq-canvas .grid.seq-in .seq-bottom,
    .seq .seq-canvas .grid.seq-in .seq-top {
        -webkit-transform: translateY(0) translateZ(0) !important;
        transform: translateY(0) translateZ(0) !important
    }
}

@media (min-width:769px) {
    .seq .seq-canvas .grid.seq-in .seq-left {
        -webkit-transform: translateZ(0) translateX(-50px);
        transform: translateZ(0) translateX(-50px)
    }
}

@media (min-width:1240px) {
    .seq .seq-canvas .grid.seq-in .seq-left {
        -webkit-transform: translateZ(0) translateX(-100px);
        transform: translateZ(0) translateX(-100px)
    }
}

@media (min-width:769px) {
    .seq .seq-canvas .grid.seq-in .seq-right {
        -webkit-transform: translateZ(0) translateX(50px);
        transform: translateZ(0) translateX(50px)
    }
}

@media (min-width:1240px) {
    .seq .seq-canvas .grid.seq-in .seq-right {
        -webkit-transform: translateZ(0) translateX(100px);
        transform: translateZ(0) translateX(100px)
    }
}

@media (min-width:769px) {

    .seq .seq-canvas .grid.seq-in .history__holder,
    .seq .seq-canvas .grid.seq-in .seq-pagination {
        opacity: 1
    }
}

@media (min-width:769px) {
    .seq .seq-canvas .grid.seq-in .history__date {
        opacity: 1;
        -webkit-transform: translateX(0) translateY(-50%) translateZ(0);
        transform: translateX(0) translateY(-50%) translateZ(0)
    }
}

@media (min-width:769px) {
    .seq .seq-canvas .seq-top {
        -webkit-transform: translateY(100%) translateZ(0);
        transform: translateY(100%) translateZ(0)
    }
}

@media (min-width:769px) {

    .seq .seq-canvas .grid.seq-out .seq-top,
    .seq .seq-canvas .seq-bottom {
        -webkit-transform: translateY(-100%) translateZ(0);
        transform: translateY(-100%) translateZ(0)
    }
}

@media (min-width:769px) {
    .seq .seq-canvas .grid.seq-out .seq-bottom {
        -webkit-transform: translateY(100%) translateZ(0);
        transform: translateY(100%) translateZ(0)
    }
}

@media (min-width:769px) {
    .seq .history__inner.seq-left .seq-pagination {
        right: auto;
        left: 10px
    }
}

@media (min-width:1240px) {
    .seq .history__inner.seq-left .seq-pagination {
        right: auto;
        left: 35px
    }
}

.seq .seq-pagination {
    display: none
}

@media (min-width:769px) {
    .seq .seq-pagination {
        position: absolute;
        display: block;
        top: 50%;
        right: 10px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 100 !important;
        margin: 0;
        padding: 0;
        list-style: none;
        line-height: 0;
        width: auto;
        height: auto;
        opacity: 0;
        transition: all .8s cubic-bezier(.645, .045, .355, 1);
        transition-delay: 1s
    }
}

@media (min-width:1240px) {
    .seq .seq-pagination {
        right: 35px
    }
}

.seq .seq-pagination li {
    display: block;
    padding: 15px 10px
}

.seq .seq-pagination li button {
    display: block;
    width: 10px;
    height: 10px;
    background: rgba(2, 63, 125, .33);
    border-radius: 50%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    transition: .3s
}

.seq .seq-pagination li.seq-current button,
.seq .seq-pagination li:hover button {
    background: $moderna;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.who-we-are {
    padding: 40px 0 0
}

@media (min-width:769px) {
    .who-we-are {
        padding: 95px 0 0
    }
}

.promises__container {
    padding: 50px 0
}

.promises .section__title {
    text-align: center
}

@media (min-width:769px) {
    .promises .section__title {
        text-align: left
    }
}

.promises__item {
    padding: 0;
    position: relative;
    border-bottom: 1px solid #dedede
}

.promises__item.open {
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, .22)
}

.promises__item:last-child {
    border-bottom: none
}

.promises__icon {
    width: 80px;
    position: absolute;
    top: 10px;
    left: 0;
    text-align: center
}

.promises__icon i {
    color: $moderna;
    font-size: 50px
}

.promises__title {
    padding: 10px 50px 10px 80px;
    line-height: 50px;
    margin-bottom: 0;
    position: relative;
    transition: .4s;
    cursor: pointer
}

.promises__title span {
    display: inline-block;
    font-family: Averta-Black, sans-serif;
    font-size: 18px;
    line-height: 25px;
    color: $moderna
}

@media (min-width:769px) {
    .promises__title span {
        font-size: 20px;
        line-height: 26px
    }
}

.open .promises__title {
    color: $moderna
}

.open .promises__title:after {
    content: "\E14C";
    color: $moderna;
    font-size: 24px
}

.promises__title:after {
    content: "\E313";
    right: 20px;
    font-family: Material Icons;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    color: $moderna;
    font-size: 18px
}

.promises__content {
    display: none;
    padding: 0 20px 50px
}

@media (min-width:769px) {
    .promises__content {
        padding: 0 100px 50px
    }
}

.fleet-graphs__container {
    padding: 20px 0 70px
}

@media (min-width:769px) {
    .fleet-graphs__container {
        padding: 70px 0
    }
}

.fleet-graphs__heading {
    padding: 25px 0;
    margin-bottom: 55px
}

@media (min-width:769px) {
    .fleet-graphs__heading {
        border-bottom: 1px solid #dedede
    }
}

@media (min-width:769px) {
    .fleet-graphs__heading .grid--center>.grid__item {
        text-align: center
    }
}

.fleet-graphs__heading .hide-in-mob {
    display: none
}

@media (min-width:769px) {
    .fleet-graphs__heading .hide-in-mob {
        display: block
    }
}

.fleet-graphs__label {
    font-family: Averta-Black, sans-serif;
    font-size: 28px;
    line-height: 36px;
    color: $moderna;
    display: block;
    margin-bottom: 30px
}

.fleet-graphs__legends {
    text-align: left;
    display: inline-block
}

.fleet-graphs__legend {
    position: relative;
    margin: 5px 30px 5px 0
}

.fleet-graphs__legend span {
    display: inline-block;
    vertical-align: middle
}

@media (min-width:769px) {
    .fleet-graphs__legend {
        margin: 5px 80px 5px 0
    }
}

.fleet-graphs__legend:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 45px;
    height: 30px;
    background: $moderna;
    border-radius: 3px;
    margin-right: 10px
}

.fleet-graphs__legend.orange:before {
    background: #58575c
}

.fleet-graphs__legend.blue:before {
    background: $moderna
}

.fleet-graphs__legend.cyan:before {
    background: $moderna
}

.fleet-graphs__legend.light-blue:before {
    background: $moderna
}

.fleet-graphs__legend.green:before {
    background: #143f3c
}

.fleet-graphs__charts {
    display: none
}

@media (min-width:769px) {
    .fleet-graphs__charts {
        display: block
    }
}

.fleet-graphs__bar-chart {
    display: block
}

@media (min-width:769px) {
    .fleet-graphs__bar-chart {
        display: none
    }
}

.sequences-sunburst {
    display: none
}

@media (min-width:769px) {
    .sequences-sunburst {
        display: inline-block
    }
}

.sequences-sunburst__wrapper {
    margin: 0 auto;
    text-align: center
}

.sequences-sunburst__chart {
    position: relative
}

.sequences-sunburst__chart path {
    stroke: #fff
}

.sequences-sunburst__label {
    font-family: Averta-Black, sans-serif
}

.sequences-sunburst__label text {
    display: block;
    font-size: 24px;
    line-height: 28px
}

.sequences-sunburst__label text:first-child {
    font-size: 30px;
    line-height: 34px
}

.sequences-sunburst__explanation {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: $moderna;
    font-family: Averta-Black, sans-serif;
    font-size: 12px;
    z-index: -1;
    width: 150px
}

.bar-graph {
    margin-bottom: 50px
}

.bar-graph:last-child {
    margin-bottom: 0
}

.bar-graph .section__title {
    text-align: center
}

.bar-graph__headings {
    margin-bottom: 40px;
    display: block;
    *zoom: 1
}

.bar-graph__headings:after,
.bar-graph__headings:before {
    content: " ";
    display: table
}

.bar-graph__headings:after {
    clear: both
}

@media (min-width:520px) {
    .bar-graph__headings {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }
}

.bar-graph__category {
    position: relative;
    color: #9d9d9d;
    padding: 15px 10px;
    text-align: left;
    transition: .4s;
    cursor: pointer;
    float: left;
    width: 50%
}

.bar-graph__category:after,
.bar-graph__category:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #9d9d9d
}

@media (min-width:520px) {
    .bar-graph__category {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        float: none;
        width: auto
    }
}

.bar-graph__category:after {
    background: $moderna;
    transition: .4s;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left
}

.bar-graph__category.orange:after {
    background: #58575c
}

.bar-graph__category.blue:after {
    background: $moderna
}

.bar-graph__category.cyan:after {
    background: $moderna
}

.bar-graph__category.light-blue:after {
    background: $moderna
}

.bar-graph__category.green:after {
    background: #143f3c
}

.bar-graph__category.active,
.bar-graph__category:hover {
    color: $moderna
}

.bar-graph__category.active.orange,
.bar-graph__category:hover.orange {
    color: #58575c
}

.bar-graph__category.active.blue,
.bar-graph__category:hover.blue {
    color: $moderna
}

.bar-graph__category.active.cyan,
.bar-graph__category:hover.cyan {
    color: $moderna
}

.bar-graph__category.active.light-blue,
.bar-graph__category:hover.light-blue {
    color: $moderna
}

.bar-graph__category.active.green,
.bar-graph__category:hover.green {
    color: #143f3c
}

.bar-graph__category.active:after,
.bar-graph__category:hover:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.bar-graph__category.active {
    cursor: default
}

.bar-graph__block {
    display: none
}

.bar-graph__block.open {
    display: block
}

.bar-graph__subcategory {
    padding: 18px 0;
    border-bottom: 1px solid #dedede;
    *zoom: 1
}

.bar-graph__subcategory:last-child {
    border-bottom: none
}

.bar-graph__subcategory:after,
.bar-graph__subcategory:before {
    content: " ";
    display: table
}

.bar-graph__subcategory:after {
    clear: both
}

.bar-graph__subcategory-name {
    line-height: 30px;
    float: left;
    width: 135px
}

.bar-graph__subcategory-count {
    width: calc(100% - 135px);
    float: left;
    position: relative
}

.bar-graph__subcategory-count span {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    padding: 0 10px;
    line-height: 30px;
    opacity: 0;
    z-index: 2
}

.in-view .animate .bar-graph__subcategory-count .bar-graph__subcategory-bar {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.in-view .animate .bar-graph__subcategory-count span {
    opacity: 1
}

.bar-graph__subcategory-bar {
    display: block;
    height: 30px;
    min-width: 28px;
    background: $moderna;
    border-radius: 4px;
    transition: .5s;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left
}

.orange .bar-graph__subcategory-bar {
    background: #58575c
}

.blue .bar-graph__subcategory-bar {
    background: $moderna
}

.cyan .bar-graph__subcategory-bar {
    background: $moderna
}

.light-blue .bar-graph__subcategory-bar {
    background: $moderna
}

.green .bar-graph__subcategory-bar {
    background: #143f3c
}

.offices__container {
    padding: 40px 0 0
}

@media (min-width:769px) {
    .offices__container {
        padding: 95px 0 0
    }
}

.offices__list {
    background: #eee;
    padding: 30px 20px
}

@media (min-width:769px) {
    .offices__list {
        padding: 30px
    }
}

@media (min-width:769px) {
    .offices__row {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: stretch;
        -ms-flex-pack: stretch;
        justify-content: stretch;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #dedede
    }
}

@media (min-width:769px) {
    .offices__row:first-child .offices__item {
        padding: 0;
        width: 100%;
        -webkit-flex: 1 100%;
        -ms-flex: 1 100%;
        flex: 1 100%
    }
}

.offices__row:first-child .offices__item .offices__title {
    font-size: 35px;
    line-height: 43px;
    color: $moderna
}

.offices__row:first-child .offices__item .offices__subtitle {
    color: $moderna
}

@media (min-width:769px) {
    .offices__row:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none
    }
}

.offices__item {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #dedede;
    padding: 20px 0
}

@media (min-width:769px) {
    .offices__item {
        padding: 20px;
        border-bottom: none;
        border-left: 1px solid #dedede
    }

    .odd .offices__item {
        width: 50%
    }

    .even .offices__item {
        width: 33.333%
    }
}

@media (min-width:769px) {
    .offices__item:first-child {
        padding-left: 0;
        border-left: none
    }
}

.offices__title {
    font-size: 24px;
    line-height: 32px;
    color: $light_grey
}

.offices__subtitle {
    color: gray
}

.offices__description,
.offices__subtitle {
    font-size: 14px;
    line-height: 21px
}

.offices__notice {
    padding: 40px 0;
    border-bottom: 1px solid #dedede
}

@media (min-width:769px) {
    .offices__notice {
        padding: 40px 0 100px
    }
}

.offices__notice-title {
    font-family: Averta-Black, sans-serif;
    color: $moderna;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px
}

@media (min-width:769px) {
    .offices__notice-title {
        margin-bottom: 0
    }
}

.offices__notice-content {
    font-size: 14px;
    color: #777
}

.offices__notice-content p:first-child {
    margin-top: 0
}

.on-board {
    position: relative;
    width: 100%
}

.on-board__container {
    padding: 40px 0
}

@media (min-width:769px) {
    .on-board__container {
        padding: 95px 0
    }
}

.on-board .text {
    margin-bottom: 0
}

.on-shore {
    position: relative;
    width: 100%
}

.on-shore__container {
    padding: 40px 0
}

@media (min-width:769px) {
    .on-shore__container {
        padding: 95px 0
    }
}

.on-shore .text {
    margin-bottom: 0
}

.positions {
    position: relative;
    width: 100%
}

.positions__container {
    padding: 90px 0 0
}

.philosophy {
    position: relative;
    width: 100%
}

.philosophy__container {
    padding: 40px 0
}

@media (min-width:769px) {
    .philosophy__container {
        padding: 95px 0
    }
}

.hero-slider {
    position: relative;
    width: 100%;
    height: 215px
}

.hero-slider .moz-background-picture:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.13);
} 


@media (min-width:520px) {
    .hero-slider {
        height: calc(100vh - 75px);
        max-height: 500px
    }
}

@media (min-width:769px) {
    .hero-slider {
        top: 0;
        left: 0;
        height: calc(100vh - 100px);
        max-height: none;
        opacity: 1;
        -webkit-transform: translateY(0) translateZ(0);
        transform: translateY(0) translateZ(0);
        transition: opacity .8s, -webkit-transform .8s cubic-bezier(.59, .01, .28, 1);
        transition: transform .8s cubic-bezier(.59, .01, .28, 1), opacity .8s;
        transition: transform .8s cubic-bezier(.59, .01, .28, 1), opacity .8s, -webkit-transform .8s cubic-bezier(.59, .01, .28, 1);
        z-index: 0
    }

    .scrolled .hero-slider {
        
    }
}

.hero-slider__container {
    position: relative;
    opacity: 0;
    transition: opacity 1s cubic-bezier(.895, .03, .685, .22) .5s
}

.loaded .hero-slider__container {
    opacity: 1
}

.hero-slider__figure {
    position: relative;
    height: 215px
}

@media (min-width:520px) {
    .hero-slider__figure {
        height: calc(100vh - 75px);
        max-height: 500px
    }
}

@media (min-width:769px) {
    .hero-slider__figure {
       height: calc(100vh - 100px);
        max-height: none
    }
}

.hero-slider__info {
    position: absolute;
    width: 100%;
    top: auto;
    bottom: 50px;
    left: 0;
     &.center_right,
        &.right{
            text-align: right;
        }
        &.center_left,
         &.left{
            text-align: left;
        }
        &.center,
        &.bottom{
            text-align: center;
        }
        &.center,
        &.center_right,
        &.center_left{
           top: 50%;
           bottom: auto;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
           transform: translateY(-50%);
        }
   &.dark{
        .hero-slider__title{
            color: #000;
            text-shadow: 0 2px 4px rgba(255,255,255,.33);
        }
        
    }

}

@media (min-width:769px) {
    .hero-slider__info {
        bottom: 190px;
    }
}



.hero-slider__title {
    font-size: 21px;
    line-height: 25px;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, .33)
}

@media (min-width:769px) {
    .hero-slider__title {
        font-size: 60px;
        line-height: 72px
    }
}

.hero-slider__title-word {
    display: inline-block
}

.hero-slider__title-word span {
    display: inline-block;
    opacity: 0;
    -webkit-transform: translateY(33px);
    -ms-transform: translateY(33px);
    transform: translateY(33px);
    transition: .5s
}

.hero-slider__title-word.animated span {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.hero-slider__scroll {
    display: none
}

@media (min-width:769px) {
    .hero-slider__scroll {
        position: absolute;
        display: block;
        bottom: 40px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        color: #fff;
        cursor: pointer;
        z-index: 7
    }

    .hero-slider__scroll span {
        display: block;
        text-align: center;
        font-size: 20px;
        line-height: 20px
    }
}
svg.arrows {
    width: 60px;
    height: 72px;
}

svg.arrows path {
    stroke: #fff;
    fill: transparent;
    stroke-width: 1px;  
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow{
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/{
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
}

svg.arrows path.a1 {
    animation-delay:-1s;
    -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

svg.arrows path.a2 {
    animation-delay:-0.5s;
    -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

svg.arrows path.a3 {   
    animation-delay:0s;
    -webkit-animation-delay:0s; /* Safari 和 Chrome */
}

.hero-slider__dots {
    position: absolute;
    width: 100%;
    max-width: 1240px;
    left: 0;
    bottom: 0;
    padding: 0 20px;
    margin: 20px 0
}

@media (min-width:769px) {
    .hero-slider__dots {
        bottom: 100px
    }
}

@media (min-width:1240px) {
    .hero-slider__dots {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

.hero-slider__loader {
    position: absolute;
    width: 100%;
    height: 3px;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: 5
}

.hero-slider__loader:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $moderna;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: 0s
}

.hero-slider__loader.in-progress:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    transition: 4s
}

@media (min-width:769px) {
    .hero-slider__loader {
        top: 0px
    }
}

@media (min-width:1025px) {
    .fixed-banner--visible .hero-slider__loader {
        top: 112px;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.hero-video {
    position: relative;
    width: 100%;
    overflow: hidden
}

@media (min-width:769px) {
    .hero-video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        -webkit-transform: translateY(0) translateZ(0) scale(1);
        transform: translateY(0) translateZ(0) scale(1);
        transition: opacity .8s, -webkit-transform .8s cubic-bezier(.59, .01, .28, 1);
        transition: transform .8s cubic-bezier(.59, .01, .28, 1), opacity .8s;
        transition: transform .8s cubic-bezier(.59, .01, .28, 1), opacity .8s, -webkit-transform .8s cubic-bezier(.59, .01, .28, 1);
        z-index: 0;
        overflow: hidden;
        opacity: 1
    }
}

@media (min-width:769px) {
    .scrolled .hero-video {
        -webkit-transform: translateY(-50%) translateZ(0);
        transform: translateY(-50%) translateZ(0);
        opacity: 0
    }
}

.hero-video__container {
    height: 100%;
    position: relative;
    opacity: 0;
    transition: opacity 1s cubic-bezier(.895, .03, .685, .22) .5s
}

.loaded .hero-video__container {
    opacity: 1
}

.hero-video__video {
    position: relative
}

@media (min-width:769px) {
    .hero-video__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh
    }
}

.hero-video__play {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 7
}

.hero-video__play.hide {
    display: none
}

.hero-video__play-btn {
    display: inline-block;
    transition: all .5s ease
}

.hero-video__play-btn svg {
    -webkit-transform: scale(.6);
    -ms-transform: scale(.6);
    transform: scale(.6)
}

.hero-video__play-btn .triangle {
    transition: all .7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: #fff;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.hero-video__play-btn .circle {
    stroke: $moderna;
    stroke-dasharray: 650;
    stroke-dashoffset: 650;
    transition: all .5s ease-in-out;
    opacity: .3
}

.hero-video__play-btn:hover .triangle {
    stroke-dashoffset: 0;
    opacity: 1;
    stroke: $moderna;
    -webkit-animation: d .7s ease-in-out;
    animation: d .7s ease-in-out
}

@-webkit-keyframes d {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    30% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }

    50% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    70% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes d {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    30% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px)
    }

    50% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px)
    }

    70% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.hero-video__play-btn:hover .circle {
    stroke-dashoffset: 0;
    opacity: 1
}

.hero-video__volume {
    opacity: 1;
    cursor: pointer;
    z-index: 5;
    position: absolute;
    bottom: 34px;
    right: 20px;
    display: block;
    width: 30px;
    height: 30px
}

.hero-video__volume .eq {
    position: absolute;
    top: 7px;
    left: 5px;
    display: block;
    width: 20px;
    height: 15px
}

.hero-video__volume .eq__bar {
    position: absolute;
    bottom: 0;
    display: block;
    float: left;
    width: 0;
    height: 15px;
    border-left: 2px solid #fff;
    transition: height .2s ease-out .2s
}

.hero-video__volume .eq__1 {
    left: 0
}

.hero-video__volume .eq__2 {
    left: 4px
}

.hero-video__volume .eq__3 {
    left: 8px
}

.hero-video__volume .eq__4 {
    left: 12px
}

.hero-video__volume .eq__5 {
    left: 16px
}

.hero-video__volume .eq--on .eq__bar {
    -webkit-animation-name: e;
    animation-name: e;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.hero-video__volume .eq--on .eq__1 {
    -webkit-animation-duration: .6s;
    animation-duration: .6s
}

.hero-video__volume .eq--on .eq__2 {
    -webkit-animation-duration: .5s;
    animation-duration: .5s
}

.hero-video__volume .eq--on .eq__3 {
    -webkit-animation-duration: .58s;
    animation-duration: .58s
}

.hero-video__volume .eq--on .eq__4 {
    -webkit-animation-duration: .52s;
    animation-duration: .52s
}

.hero-video__volume .eq--on .eq__5 {
    -webkit-animation-duration: .56s;
    animation-duration: .56s
}

.hero-video__volume .eq--off .eq__bar {
    -webkit-animation-name: f;
    animation-name: f;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

@-webkit-keyframes e {

    0%,
    to {
        height: 8px
    }

    50% {
        height: 15px
    }
}

@keyframes e {

    0%,
    to {
        height: 8px
    }

    50% {
        height: 15px
    }
}

@-webkit-keyframes f {
    0% {
        height: 15px
    }

    to {
        height: 3px
    }
}

@keyframes f {
    0% {
        height: 15px
    }

    to {
        height: 3px
    }
}

.hero-video__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .4s ease;
    z-index: 6;
    opacity: 1;
    visibility: visible
}

.hero-video__image.hide {
    opacity: 0;
    visibility: hidden
}

.hero-video .ytplayer-container {
    height: 0;
    padding-bottom: 56.25%
}

@media (min-width:769px) {
    .hero-video .ytplayer-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        padding: 0;
        height: auto
    }
}

.hero-video .ytplayer-container iframe {
    position: relative
}

@media (max-width:768px) {
    .hero-video .ytplayer-container iframe {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        left: 0 !important
    }
}
@media (min-width:769px) {
    .text {
        margin-bottom: 60px
    }
}

.text {
    margin-bottom: 30px
}


.text hr {
    width: 100%;
    height: 1px;
    border-top: 1px solid #dedede;
    margin: 60px 0
}

.text .section__content {
    margin-bottom: 0
}

.text .section__content h1,
.text .section__content h2,
.text .section__content h3,
.text .section__content h4,
.text .section__content h5,
.text .section__content h6 {
    font-family: Averta-Black, sans-serif;
    color: $moderna;
    margin: 2em 0 1em;
    line-height: 1;
}


.text .section__content h1 {
   font-size: 48px;
}

.text .section__content h2 {
    font-size: 1.750em;
}


.text .section__content h3{
    font-size: 1.5em;
}


.text .section__content h4{
    font-size: 1.375em;
   
}

.text .section__content h5{
    font-size: 1.250em;
   
}

.text .section__content h6{
    font-size: 1.125em;
    
}

.text .section__content a {
    color: $moderna;
    text-decoration: none
}


.text__content p:first-child {
    margin-top: 0
}

.text__content p:last-child {
    margin-bottom: 0
}
 
.block__content p,
.text__content p{
    text-align: justify;
}
.text__extra-content {
    display: none
}

.text__extra-content p:last-child {
    margin-bottom: 1em
}

.text__read-more {
    color: $moderna;
    text-decoration: none;
    padding-top: 50px
}

.text__read-more span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    line-height: 20px
}

.text__read-more span:after {
    content: "+";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -20px;
    height: 10px;
    display: inline-block;
    margin-left: 10px;
    line-height: 6px
}

.text__read-more.read-more--triggered span:after {
    content: "-"
}

.figure {
    position: relative;
    width: calc(100% - 20px);
    margin-left: 20px
}

@media (min-width:900px) {
    .figure {
        width: calc((840px + ((100% - 840px)/2)));
        margin-left: calc(((100% - 840px)/2))
    }
}

.figure--glance {
    width: 100%;
    margin-left: 0
}

@media (min-width:769px) {
    .figure--glance {
        width: 66.66667%;
        float: left
    }
}

@media (min-width:1240px) {
    .figure--glance {
        width: calc(800px + ((100% - 1200px)/2))
    }
}

.figure--philosophy {
    margin-top: 40px
}

@media (min-width:769px) {
    .figure--philosophy {
        margin-top: 95px
    }
}

.figure--philosophy .slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important
}


.site__main-body .figure__container {
    white-space: nowrap;
    overflow: hidden;
    opacity: 0
}

.figure__container.slick-initialized {
    opacity: 1;
    top: 30px;
}

.figure__slide {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    outline: none;
    padding-right: 20px;
    text-align: center;
    white-space: normal;
}

@media (min-width:769px) {
    .figure__slide {
        text-align: left;
    }
}

.figure__figure {
    font-size: 60px;
    line-height: 70px;
    color: $moderna;
    margin-bottom: 50px;
    svg{
        fill: $moderna;
        height:60px;
        display: block;
        margin: 0px auto 15px; 
        max-width: 120px;
        
    }
}

@media (min-width:769px) {
    .figure__figure {
        font-size: 72px;
        line-height: 82px
    }
}

.figure__content {
    width: 100%;
    font-family: Averta-Bold;
    color: $grey;
    text-align: center;
}

@media (min-width:769px) {
    .figure__content {
       
    }
}

.figure__dots {
    padding: 40px 0 20px;
    text-align: center
}

@media (min-width:769px) {
    .figure__dots {
        padding: 40px 0;
        text-align: center
    }
}

.figure__dots .slick-dots li button {
    background: #bfc0c1
}

.figure__dots .slick-dots li.slick-active button,
.figure__dots .slick-dots li:hover button {
    background: #58575c
}

.related-banners {
    position: relative;
    background-color:$moderna;
    overflow: hidden;
    .section__title{
        font-family: Averta-Black,sans-serif;
    }
    
}



.related-banners__container {
    padding: 30px 0;
    position:relative;
    z-index: 3;
}

.related-gallery__item,
.related-banners__item {
    height:370px;
    position: relative;
    text-decoration: none;
}

.related-gallery__item .moz-background-picture,
.related-banners__item .moz-background-picture {
    position: relative
}

.related-banners__item .moz-background-picture:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .33);
    transition: .4s
}

@media (min-width:769px) {
    .related-gallery__item,
    .related-banners__item {
        transform: scale(1);
        transition: .4s
    }
    .related-gallery__item,
     .related-banners__item:hover{
         transform: scale(1);
     }
}

@media (min-width:769px) {
    .related-banners__item:hover .moz-background-picture:after {
        background: rgba(0, 0, 0, .55)
    }
}

.related-banners__info {
    position: absolute;
    bottom: 30px;
    width: calc(100% - 40px);
    left: 20px;
}

@media (min-width:769px) {
    .related-banners__info {
        width: calc(100% - 80px);
        left: 40px
    }
}

.related-banners__title {
    color: #fff;
    font-size: 28px;
    line-height: 34px;
    padding: 0 0 20px ;
    font-family: Averta-Black,sans-serif;
    border-bottom: 3px solid hsla(255, 0%, 100%, 1);
}

.related-banners__body {
    display: block
}

@media (min-width:769px) {
    .related-banners__body {
        display: none
    }

    .touch-device .related-banners__body {
        display: block
    }
}

.related-banners__label {
    color: #fff;
    margin-bottom: 20px
}

.related-gallery__dots .slick-dots ,
.related-banners__dots .slick-dots {
    padding: 35px 0;
    text-align:center;
}


.related-banners .slick-slide {
    margin: 0 10px
}

.related-banners .slick-list {
    margin: 0 -10px
}

.info-block {
    overflow: hidden
}

.info-block,
.info-block__container {
    position: relative
}

.related-gallery.related-banners{
    background-color:transparent;
}
.related-gallery__slider{
    .slick-arrow{
        background-color: rgba(135,171,189,.33);
           
    }

    .related-gallery__item{
         max-height: 300px;
    }
}


@media (min-width:769px) {
    .info-block__container {
        padding: 110px 0
    }
}

.info-block__image {
    position: relative;
    height: 250px
}

@media (min-width:520px) {
    .info-block__image {
        height: 485px
    }
}

@media (min-width:769px) {
    .info-block__image {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        overflow: hidden
    }
}

@media (min-width:769px) {
    .info-block__image span {
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        transition: -webkit-transform .9s cubic-bezier(0, 0, .1, 1) .2s;
        transition: transform .9s cubic-bezier(0, 0, .1, 1) .2s;
        transition: transform .9s cubic-bezier(0, 0, .1, 1) .2s, -webkit-transform .9s cubic-bezier(0, 0, .1, 1) .2s
    }
}

@media (min-width:769px) {
    .in-view .info-block__image span {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

.info-block__image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url(/skin/frontend/default/img/overlays/overlaypatternbig2.png) repeat;
    display: none;
    
}

@media (min-width:769px) {
    .info-block__image:after {
         display: block;
    }
    
}

.info-block__image.orange:after {
    background: rgba(255, 142, 43, .66)
}

.info-block__image.blue:after {
    background: rgba(2, 63, 125, .66)
}

.info-block__info {
    padding: 50px 0
}
@media (min-width:900px) {
      .info-block__info {
         width: 50%;
      }
    
}
@media (min-width:769px) {
    .info-block__info {
       
        padding: 50px;
        -webkit-transform: translateX(-80px);
        -ms-transform: translateX(-80px);
        transform: translateX(-80px);
        opacity: 0;
        transition: .5s .8s;
        min-height: 620px;
        display: table
    }

    .in-view .info-block__info {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
}

@media (min-width:769px) {
    .info-block__info-title{
        background-color:$moderna;
        display:block;

    }
    .info-block__holder {
         background:rgba(51,51,51,.85) ;
        padding: 50px;
        .section__content{
            color:#fff;
        }
        .skew-button{
            float: right;
            color: #fff;
        }
    }
}

@media (min-width:1240px) {
    .info-block__inner {
        width: 80%;
        margin: 0 auto
    }
}

.info-block__info-title {
    color: $moderna;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 30px
}

@media (min-width:769px) {
    .info-block__info-title {
         color: #fff;
        font-size: 32px;
        line-height: 44px;
        padding: 15px;
    }
}

@media (min-width:1025px) {
    .info-block__info-title {
        font-size: 36px;
        line-height: 48px
    }
}

.info-block__title {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    color: #fff;
    font-size: 48px;
    line-height: 58px;
    z-index: 1;
    padding: 0 20px
}

@media (min-width:769px) {
    .info-block__title {
        width: 50%;
        left: 50%;
        font-size: 60px;
        line-height: 70px;
        -webkit-transform: translate(-80px, -50%);
        -ms-transform: translate(-80px, -50%);
        transform: translate(-80px, -50%);
        opacity: 0;
        transition: .5s 1.3s
    }

    .in-view .info-block__title {
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 1
    }
}

@media (min-width:1240px) {
    .info-block__title {
        font-size: 99px;
        line-height: 109px
    }
}

@media (min-width:1540px) {
    .info-block__title {
        width: 770px
    }
}

.join {
    position: relative;
    width: 100%
}

.join .promises__title,
.join .section__content h1,
.join .section__content h2,
.join .section__content h3 h4,
.join .section__content h5,
.join .section__content h6,
.join .section__subtitle,
.section__content .join h1,
.section__content .join h2,
.section__content .join h5,
.section__content .join h6,
.section__content h3 .join h4 {
    color: $moderna
}

@media (min-width:769px) {
    .fleet-content{
        max-width: 500px;
    }

}
                                        
.join__container {
    padding: 30px 0
}

.join__cta {
    text-align: left
}

@media (min-width:769px) {
    .join__cta {
        text-align: right
    }
}

.join .section__content {
    color: #fff;
    margin-bottom: 30px
}

@media (min-width:769px) {
    .join .section__content {
        margin-bottom: 0
    }
}

.join.fixed-banner {
    display: none
}

@media (min-width:1025px) {
    .join.fixed-banner {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        transition: .6s;
        z-index: 12;
        background: #58575c
    }
}

@media (min-width:1025px) {
    .fixed-banner--visible .join.fixed-banner {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

.join.fixed-banner .join__container {
    padding: 25px 0
}

.join.fixed-banner .join-wrapper {
    *zoom: 1
}

.join.fixed-banner .join-wrapper:after,
.join.fixed-banner .join-wrapper:before {
    content: " ";
    display: table
}

.join.fixed-banner .join-wrapper:after {
    clear: both
}

.join.fixed-banner .join-wrapper .promises__title,
.join.fixed-banner .join-wrapper .section__content h1,
.join.fixed-banner .join-wrapper .section__content h2,
.join.fixed-banner .join-wrapper .section__content h3 h4,
.join.fixed-banner .join-wrapper .section__content h5,
.join.fixed-banner .join-wrapper .section__content h6,
.join.fixed-banner .join-wrapper .section__subtitle,
.section__content .join.fixed-banner .join-wrapper h1,
.section__content .join.fixed-banner .join-wrapper h2,
.section__content .join.fixed-banner .join-wrapper h5,
.section__content .join.fixed-banner .join-wrapper h6,
.section__content h3 .join.fixed-banner .join-wrapper h4 {
    float: left;
    width: 20%;
    margin-bottom: 0
}

.join.fixed-banner .join-wrapper .section__content {
    float: left;
    width: 60%
}

.join.fixed-banner .join-wrapper .join__cta {
    float: right;
    width: 20%;
    text-align: right
}

.join.fixed-banner .join-wrapper .join__cta a {
    margin: 0;
    font-size: 15px;
    padding: 18px 30px 16px
}

.video {
    position: relative;
    width: 100%
}

.video__container {
    padding: 50px 0
}

.video__image {
    position: relative;
    width: 100%;
    height: 640px;
    display: block
}

.mfp-iframe-scaler iframe {
    box-shadow: none !important
}

label{
    font-family: Averta-Black,sans-serif;
}
.form-control{
    border-radius: 0px;
}
.form-control:focus {
    border-color: $moderna;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(19,174,201,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(19,174,201,.6);
}

input[type=checkbox]:checked, input[type=checkbox]:not(:checked), 
input[type=radio]:checked, input[type=radio]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input[type=checkbox]+label, input[type=radio]+label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    font-size: 14px;
    letter-spacing: -.3px;
}

input[type=checkbox]:checked+label:before, input[type=checkbox]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid $grey;
    background: 0 0;
    border-radius: 0;
    box-shadow: none;
}


input[type=checkbox]:not(:checked)+label:after, input[type=radio]:not(:checked)+label:after {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
}
input[type=checkbox]:checked+label:after, input[type=checkbox]:not(:checked)+label:after {
    content: '✔';
    position: absolute;
    left: 6px;
    top: 5px;
    color: $moderna;
    transition: all .2s;
    line-height: 1;
}


.skew-button {
    cursor: pointer;
    outline: none;
    backface-visibility: hidden;
    position: relative;
    display: table;
    margin: 15px auto;
    padding: 0;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    transition: all 100ms ease-out;
    color: #000;
    text-shadow: 0 0 3px rgba(255,255,255,.5);
     @media (min-width:769px) {
         display: inline-block;
          margin: 15px 0;
     }
    
    // Text
    span {
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px 30px;
        z-index: 3;
    }
    
    // Border
    &:before,
    span:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) skewX(-20deg);
        width: 100%;
        height: 50px;
        max-width: 200px;
        border: 1px solid $moderna;
        z-index: 2;
    }
    
    span:before {
        display: block;
        transform: translate(-50%, -50%) skew(-20deg);
        transform-origin: center center;
        z-indeX: -1;
    }
    
    // Fill
    &:after {
        transition: all 100ms ease-out;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        transform: skewX(-20deg) scaleX(0);
        width: 100%;
        height: 100%;
        background-color: $moderna;
        border: 1px solid transparent;
        z-index: 1;
    }
    
    // Hover
    &:hover {
        color: #fff;
        text-shadow: 0 0 3px rgba(0,0,0,.5);
        span:before {
            animation: fill .5s ease-out;
            animation-fill-mode: forwards;
        }
        
        &:after {
            animation: swoosh .7s ease-in;
        }
    }
    
    // Active
    &:active {
        &:before {
            background-color: darken($moderna, 20%);
        }
    }
}


// Animation
// ==========================================================================
@keyframes swoosh {
    0% {
        transform-origin: left center;
    }
    
    20% {
        transform: skewX(-20deg) scaleX(1);
        transform-origin: left center;
    }
    
    21% {
        transform-origin: right center;
    }
    
    50% {
        transform: skewX(-20deg) scaleX(0);
        transform-origin: right center;
    }
}

@keyframes fill {
    0% {
        background-color: rgba($moderna, 0);
    }
    
    20% {
        opacity: .5;
        border-width: 15px;
        border-color: darken($moderna, 10%);
    }
    
    100% {
        opacity: .5;
        border-width: 1px;
        background-color: rgba($moderna, 0.5);
    }
}



.image-content{
    display: block;
    width: 100%;
    padding-bottom:50px;
    background-color:#f7f7f7;
    
    position:relative;
    .block__content,
    .block__image{
        width: 100%;
        float: none;
        padding:10px 0 30px;
         @media (min-width:769px) {
            padding:0 50px;
         }
        
    }
    .block-title{
        display: block;
        width: 100%;
        text-align: left;
        padding:0;
         @media (min-width:769px) {
            padding:0 50px;
         }
    }
    @media (min-width:769px) {
        .block__content,
        .block__image{
            width: 50%;
            float: left;
            padding:0 50px;
        }
         .block-title{
            text-align: right;
        }
    }
   
    
    .block__image{
        img{
            display: block;
            max-width: 100%;
            height: auto;
            width: auto!important;
            margin:0 auto!important; 
            max-height: 500px;
        }
    }
    
    &:nth-child(even){
        background-color:#fff;
         @media (min-width:769px) {
            .block__content,
            .block__image{
                float: right;
            }
             .block-title{
                text-align: left;
            }
         }
         
    }
}

.site__main-body .image-content{
    opacity: 0;
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    transition: .5s .3s;
    &.in-view {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

}


.banners-arrows.arrows{
    transform: rotate(-90deg) translateY(-50%);
    position:absolute;
    right: 0;
    width: 32px;
    height: 40px;
}


.positions__results{
  @media (min-width:769px) {
    padding-bottom: 90px;
  }
   @media (max-width:768px) {
        .table .tr>.td:last-child {
           display: none;
        }
   }
    .table .tr>.td:last-child {
        color: $moderna;
    }

}
.canvas-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image:url('/skin/frontend/default/img/overlays/bg-pattern.jpg');
    background-repeat: repeat;
    background-size:100% auto;
}
.g-recaptcha{
    float: none;
      @media (min-width:769px) {
        float: right;
     }
}

.figure__dots ul li:before{
    display: none;

}

.simple-text__container .figure__dots {
    padding: 0px;
    text-align: center;
}


/*<--START COOKIEBAR***************/
#cookie-bar {
    background: rgba(77,77,79,.7);
    height: auto;
    color: #fff;
    padding: 10px 20px;
    font-size: 12px;
    text-align: left;
    position: fixed;
    width: 100%;
    bottom: 0;
    &:after,
    &:before {
        content: " ";
        display: table
    }
    &:after {
        clear: both
    }
    &.fixed {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
    }
    &.fixed.bottom {
        bottom: 0;
        top: auto;
    }
     p{
       
        margin: 8px 0;
        padding: 0;  
        float: left;  
        line-height: 20px;
         @media only screen and (max-width: 1024px){
            width: 100%;
            text-align: center;
         } 
    }
     a {
        color: #fff;
        display: inline-block;
        border-radius: 0px;
        text-decoration: none;
        padding: 5px 15px;
        margin-left: 8px;
        margin-bottom: 5px;
        border: $moderna solid 1px;
        text-transform: uppercase;
    }
    
    .groupedContent{
        display:table; 
        float: right;
         @media only screen and (max-width: 1024px){
            margin: 0 auto;
             float: none;
         }
        &:after,
        &:before {
            content: " ";
            display: table
        }
        &:after {
            clear: both
        }
    }
    .cb-enable,
    .cb-policy{
        background-color: $moderna;
        border-color:  $moderna;
        float: right;
        display: table;
         @media only screen and (max-width: 1024px){
             float: left;
         }
        @media only screen and (max-width: 480px){
            float: none;
            margin: 0 auto;
            margin-top: 10px;
        }
        &:hover{
            background-color: #084b77;
            border-color:  #084b77;
        }
    }   
    
}


#responseModal{
    &.in{
        visibility: visible;
    }    
    .modal-dialog {
        width: 100%;
        max-width: 500px;
        margin: 30px auto;
        top: 50%;
        transform: translate(0, -50%)!important;
        .modal-content {
            //background-color: transparent;
            .modal-body{
                padding: 0; 
                .alert{
                    margin: 0;
                    font-size: 18px;
                    text-align: center;
                    border-radius:0;
                    color: #333;
                    i{
                        margin: 0px auto 15px;
                        display: table;
                        font-size: 30px;
                    }
                    &.alert-error{
                         i{
                            color:#a94442;
                        }
                        background-color: #fff;
                        border-color: #fff;
                    } 
                    &.alert-success {
                        i{
                            color: $moderna;
                        }
                        
                        background-color: #fff;
                        border-color: #fff;
                    }
                }
            }
        }
        
    }
}
.bg-cyan {
    background-color:$moderna;
}
@media (max-width:769px){
.info-block__holder.error_block {
        background:rgba(51,51,51,.85) ;
        padding: 15px;
        .skew-button{
            color: #fff;
        }
    }
}
#upload-container{
    display: block;
    width: 100%;
    float: left;
    overflow: hidden;    
     @media (min-width:769px) {
         width: 40%;
         .btn-upload{
            margin: 15px;
         }
    
     }
}

#filelist{
    margin: 15px 0;
    display: block;
    width: 100%;
    float: left;
    text-align: center;
      @media (min-width:769px) {
         width: 60%;
         display: inline-block;
         padding: 20px;
      }
   
    
}
.stories__container {
    padding: 40px 0
}

@media (min-width:769px) {
    .stories__container {
        padding: 90px 0 0
    }
}

.stories__item {
    position: relative;
    display: block;
    height: 320px;
    margin-bottom: 20px;
    text-decoration: none
}

@media (min-width:769px) {
    .stories__item {
        height: 555px;
        margin-bottom: 24px
    }
}

.stories__item:hover .stories__image:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, .11) 0, rgba(0, 0, 0, .5) 50%, rgba(0, 0, 0, .88));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c000000", endColorstr="#e0000000", GradientType=0)
}

.stories__image {
    position: relative;
    height: 100%
}

.stories__image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, .11) 67%, rgba(0, 0, 0, .5) 84%, rgba(0, 0, 0, .66));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c000000", endColorstr="#a8000000", GradientType=0);
    transition: .5s
}

.stories__info {
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    left: 20px;
}

@media (min-width:769px) {
    .stories__info {
        width: calc(100% - 80px);
        left: 40px
    }
}

.stories__title {
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    padding: 20px 0
}

.stories__select-wrapper {
    width: 100%;
    padding: 20px 0
}

@media (min-width:769px) {
    .stories__select-wrapper {
        max-width: 380px;
        padding: 50px 0;
        margin: 0 auto
    }
}

.stories__dots {
    text-align: center
}

.stories__dots .slick-dots {
    padding: 35px 0
}

.stories__dots .slick-dots li button {
    background: rgba(2, 63, 125, .22)
}

.stories__dots .slick-dots li.slick-active button,
.stories__dots .slick-dots li:hover button {
    background: $moderna
}

.stories .slick-slide {
    margin: 0 10px
}

.stories .slick-list {
    margin: 0 -10px
}
.elipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stories__title .elipsis{
    display: block;
    width: calc(100% - 60px);
    float: left;
}

    

.stories .slick-arrow {
    background-color: rgba(2, 63, 125, .22)
}

.stories .slick-arrow:before {
    color: $moderna
}

.stories .slick-arrow:hover {
    background-color: $moderna
}

.stories .slick-arrow:hover:before {
    color: #fff
}

.stories .slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important
}
.h-video{
    display: block;
    position:relative;
}
.hero-slider__container   .slick-list,
.hero-slider__container .slick-slider {
    overflow: hidden;
    height: calc(100vh - 100px);
} 

video{
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    z-index: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    vertical-align: baseline;
}

.columns-2-g-wide {
    column-count: 2;
    -moz-column-gap: 40px;
    -webkit-column-gap: 40px;
    column-gap: 40px;
    -moz-column-width: 400px;
    -webkit-column-width: 400px;
    column-width: 400px;
}
@media (max-width:480px) {
    .table__info-row-value{
        width: calc(100% - 130px);
    }
    .table__info-row-label {
        width: 130px;
    }
}

.two-rows-elipsis{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: 52px;
}

.square-border {
    position: absolute;
    pointer-events: none;
    z-index: 10;
    left: 20px;
    top: 20px;
    right: 20px;
    bottom: 20px;
    @media (max-width:767px) {
        left: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;
    }
    .square-right,
    .square-bottom,
    .square-top,
    .square-left {
        background: #fff;
        position: absolute;
        z-index: 10;
        transition: all .5s;
    }
    .square-left {
         left: 0;
        top: 0;
        width: 1px;
        bottom: 100%;
    }

    .square-right {
        right: 0;
        bottom: 0;
        width: 1px;
        top: 100%;
    }

    .square-bottom {
        bottom: 0;
        right: 0;
        height: 1px;
        left: 100%;
    }
    .square-top {
        left: 0;
        top: 0;
        height: 1px;
        right: 100%;
    }
    
   
}
.related-banners__item:hover{
    .square-border {
      .square-left {
            bottom: 0;
        }
        .square-right{
             top: 0;
        }

         .square-bottom {
            left: 0;
        }

        .square-top{
             right: 0;
        }
    }

}

@media (max-width:991px) {
  .menu__list--wrapper{
    left: 0!important;
    opacity: 1!important;
    position:relative!important;
    box-shadow:none!important;
    width: 100%!important;
    overflow-y: inherit!important;
    overflow-x: inherit!important;
    padding:0px 20px 0px!important;
    height: auto!important;
    .menu__item{
            color: rgba(255,255,255,.5)!important;
            &:hover{
                 .menu__link{
                     color:#fff!important;
                 }
               
            }
        }
        .menu__item .menu__link{
             color: rgba(255,255,255,.5)!important;
            &:hover{
                color:#fff!important;
            }
        }
  }

  .menu--main .menu__item:hover .menu__list--wrapper .menu__link {
        color: #fff!important;
        z-index: 1;
    }
}
